@media only screen and (max-width: 900px)  {
   .chatheader{
      display: none;
   }
    .groupiconscontainer{
        height:27vw;
        width:20vw;
    }
    .creategroup, .friends, .mygroups, .alerts, .mail, .giftbox{
        display: none;
    }
    .circularicongroup{
      position:relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap:1rem;
      float:right;
      width:150px;
      margin-right:9px;
      margin-top:-50px;
    }
    .circularicon{
       /* margin: 2vw;
        width: 17vw; height: 17vw;
        background: url("../icons/circular.png") no-repeat;
        background-size: 13vw 13vw;
        */
        grid-column: 1/2;
        position: absolute;
        width:60px; height:50px;
        background: url("./icons/circular.png") no-repeat;
      background-size: 50px 50px;
      background-size: contain;
    }
     
{/*
     .menu-item,
     .menu-open-button {
        background: #9b1414;
        border-radius: 100%;
        width: 15vw;
        height: 15vw;
        max-width: 50px;
        max-height: 50px;
        position: absolute;
        color: #FFFFFF;
        text-align: center;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: -webkit-transform ease-out 200ms;
        transition: -webkit-transform ease-out 200ms;
        transition: transform ease-out 200ms;
        transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
     }
     .menu-open-button {
      background: url("./icons/circular.png") no-repeat;
      background-size: 15vw 15vw;
      background-size: contain;
     }
     
     .menu-open {
        display: none;
     }
     
     .lines {
        width: 25px;
        height: 3px;
        background: #596778;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -12.5px;
        margin-top: -1.5px;
        -webkit-transition: -webkit-transform 200ms;
        transition: -webkit-transform 200ms;
        transition: transform 200ms;
        transition: transform 200ms, -webkit-transform 200ms;
     }
     
     .line-1 {
        -webkit-transform: translate3d(0, -8px, 0);
        transform: translate3d(0, -8px, 0);
     }
     
     .line-2 {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
     }
     
     .line-3 {
        -webkit-transform: translate3d(0, 8px, 0);
        transform: translate3d(0, 8px, 0);
     }
     
     .menu-open:checked + .menu-open-button .line-1 {
        -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
        transform: translate3d(0, 0, 0) rotate(45deg);
     }
     
     .menu-open:checked + .menu-open-button .line-2 {
        -webkit-transform: translate3d(0, 0, 0) scale(0.1, 1);
        transform: translate3d(0, 0, 0) scale(0.1, 1);
     }
     
     .menu-open:checked + .menu-open-button .line-3 {
        -webkit-transform: translate3d(0, 0, 0) rotate(-45deg);
        transform: translate3d(0, 0, 0) rotate(-45deg);
     }
     
     .menu {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 15vw;
        height: 15vw;
        max-width: 50px;
        max-height: 50px;
        text-align: center;
        box-sizing: border-box;
        font-size: 26px;
     }
     
     
// .menu-item {
//       transition: all 0.1s ease 0s;
//    }
 
     
     .menu-item:hover {
        background: #EEEEEE;
        color: #3290B1;
     }
     
     .menu-item:nth-child(3) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
     }
     
     .menu-item:nth-child(4) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
     }
     
     .menu-item:nth-child(5) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
     }
     
     .menu-item:nth-child(6) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
     }
     
     .menu-item:nth-child(7) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
     }
     
     .menu-item:nth-child(8) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
     }
     
     .menu-item:nth-child(9) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
     }
     
     .menu-open-button {
        z-index: 2;
        -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -webkit-transition-duration: 400ms;
        transition-duration: 400ms;
        -webkit-transform: scale(1.1, 1.1) translate3d(0, 0, 0);
        transform: scale(1.1, 1.1) translate3d(0, 0, 0);
        cursor: pointer;
        box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
     }
     
     .menu-open-button:hover {
        -webkit-transform: scale(1.2, 1.2) translate3d(0, 0, 0);
        transform: scale(1.2, 1.2) translate3d(0, 0, 0);
     }
     
     .menu-open:checked + .menu-open-button {
        -webkit-transition-timing-function: linear;
        transition-timing-function: linear;
        -webkit-transition-duration: 200ms;
        transition-duration: 200ms;
        -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
        transform: scale(0.8, 0.8) translate3d(0, 0, 0);
     }
     
     .menu-open:checked ~ .menu-item {
        -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
        transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
     }
     
     .menu-open:checked ~ .menu-item:nth-child(3) {
        transition-duration: 180ms;
        -webkit-transition-duration: 180ms;
        -webkit-transform: translate3d(0.08361px, -104.99997px, 0);
        transform: translate3d(0.08361px, 50px, 0);
        background: url("./icons/group-min.png") no-repeat;
      background-size: contain;
      z-index: 1;
     }
     
     .menu-open:checked ~ .menu-item:nth-child(4) {
        display:none;
        transition-duration: 280ms;
        -webkit-transition-duration: 280ms;
        -webkit-transform: translate3d(90.9466px, -52.47586px, 0);
        transform: translate3d(90.9466px, -52.47586px, 0);
     }
     
     .menu-open:checked ~ .menu-item:nth-child(5) {
      display:none;
        transition-duration: 380ms;
        -webkit-transition-duration: 380ms;
        -webkit-transform: translate3d(90.9466px, 52.47586px, 0);
        transform: translate3d(90.9466px, 52.47586px, 0);
     }
     
     .menu-open:checked ~ .menu-item:nth-child(6) {
        transition-duration: 480ms;
        -webkit-transition-duration: 480ms;
        /*-webkit-transform: translate3d(0.08361px, 104.99997px, 0);
        transform: translate3d(0.08361px, 104.99997px, 0);
        -webkit-transform: translate3d(0.08361px, 70px, 0);
        /*transform: translate3d(0.08361px, 70px, 0);
        transform: translate3d(60.08361px, 45px, 0);
        background: url("./icons/mail-min.png") no-repeat;
      background-size: contain;
      z-index: 1;
     }
     
     .menu-open:checked ~ .menu-item:nth-child(7) {
        transition-duration: 580ms;
        -webkit-transition-duration: 580ms;
        -webkit-transform: translate3d(-90.86291px, 52.62064px, 0);
        transform: translate3d(-90.86291px, 52.62064px, 0);
        -webkit-transform: translate3d(-60px, 32px, 0);
        transform: translate3d(-60px, 32px, 0);
        transform: translate3d(-60px, 50px, 0);
        background: url("./icons/mygroups.png") no-repeat;
      background-size: contain;
      z-index: 1;
     }
     
     .menu-open:checked ~ .menu-item:nth-child(8) {
        transition-duration: 680ms;
        -webkit-transition-duration: 680ms;
        -webkit-transform: translate3d(-91.03006px, -52.33095px, 0);
        transform: translate3d(-91.03006px, -52.33095px, 0);
        -webkit-transform: translate3d(-60x, -32px, 0);
        transform: translate3d(-60px, -32px, 0);
        transform: translate3d(-110px, 50px, 0);
        background: url("./icons/037-chat-min.png") no-repeat;
        background-position: center;
        background-size: 7vw;
        z-index: 1;
     }
     
     .menu-open:checked ~ .menu-item:nth-child(9) {
        transition-duration: 780ms;
        -webkit-transition-duration: 780ms;
        -webkit-transform: translate3d(-0.25084px, -104.9997px, 0);
        transform: translate3d(-0.25084px, -104.9997px, 0);
     }
*/}
     
     .blue {
        background-color: #669AE1;
        box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
     }
     
     .blue:hover {
        color: #669AE1;
        text-shadow: none;
     }
     
     .green {
        background-color: #70CC72;
        box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
     }
     
     .green:hover {
        color: #70CC72;
        text-shadow: none;
     }
     
     .red {
        background-color: #FE4365;
        box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
     }
     
     .red:hover {
        color: #FE4365;
        text-shadow: none;
     }
     
     .purple {
        background-color: #C49CDE;
        box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
     }
     
     .purple:hover {
        color: #C49CDE;
        text-shadow: none;
     }
     
     .orange {
        background-color: #FC913A;
        box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
     }
     
     .orange:hover {
        color: #FC913A;
        text-shadow: none;
     }
     
     .lightblue {
        background-color: #62C2E4;
        box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
     }
     
     .lightblue:hover {
        color: #62C2E4;
        text-shadow: none;
     }
     
     .credit {
        margin: 24px 20px 120px 0;
        text-align: right;
        color: #EEEEEE;
     }
     
     .credit a {
        padding: 8px 0;
        color: #C49CDE;
        text-decoration: none;
        transition: all 0.3s ease 0s;
     }
     
     .credit a:hover {
        text-decoration: underline;
     }


}

.iconanimate{
   position:relative;
   transition-property: background-size;
   transition-duration: 0.3s;
   transition-timing-function: linear;
}
.displaynone{
   display: none;
}
.alertnumber{
   position: absolute;
   top:0;
   right:0;
   margin: 15% 15% 0 0;
   background: rgb(220, 216, 245);
   border-radius: 50% 50%;
   width:20px;
   height:20px;
}
.locked{
   position: absolute;
   top:0;
   right:0;
   margin: 15% 15% 0 0;
   border-radius: 50% 50%;
   width:40px;
   height:40px;
   background: url("./icons/001-padlock.png") no-repeat rgb(220, 216, 245);;
   background-size: cover;

}
@media only screen and (min-width: 900px)  {
.leadershipiconscontainer{
   width:6vw;
   margin: 2vw auto;
   display: grid;
   grid-template-rows: 1fr 1fr 1fr;
   grid-gap: 1vw;
   box-sizing: border-box;
}
.groupiconscontainer{
    margin: 2vw 0 0 2vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 1vw;
    box-sizing: border-box;
}
.circularicon{
    display: none;
}
.achievement{
   grid-row: 1/1;
   width: 6vw; height: 6vw;
   border-radius: 50%;
   background: url("./icons/001-medal.png") no-repeat;
   background-position: center;
   background-color: #111f38;
   background-size: 3.5vw 3.5vw;
}
.achievement:hover{
   background-size: 4.5vw 4.5vw;
}
.leaderboard{
   grid-row: 2/2;
   width: 6vw; height: 6vw;
   border-radius: 50%;
   background: url("./icons/001-podium.png") no-repeat;
   background-position: center;
   background-color: #111f38;
   background-size: 3.5vw 3.5vw;
}
.leaderboard:hover{
   background-size: 4.5vw 4.5vw;
}
.tournament{
   grid-row: 3/3;
   width: 6vw; height: 6vw;
   border-radius: 50%;
   background: url("./icons/003-soccer.png") no-repeat;
   background-position: center;
   background-color: #111f38;
   background-size: 3.5vw 3.5vw;
}
.tournament:hover{
   background-size: 4.5vw 4.5vw;
}
.creategroup{
    grid-column: 1/1;
    grid-row: 1/1;
    width: 6vw; height: 6vw;
    border-radius: 50%;
    background: url("./icons/group-min.png") no-repeat;
    background-position: center;
    background-color: #111f38;
    background-size: 5vw 5vw;
}
.creategroup:hover{
   background-size: 6vw 6vw;
}
/*
.creategroup:hover{
   width: 8vw;
   height: 8vw;
   margin: 0;
   border-top: 4px solid #009688;
   border-right: 4px solid transparent;
   border-radius: 50%;
   -webkit-animation: 1s spin linear infinite;
   animation: 1s spin linear infinite;
   
}
*/
@-webkit-keyframes spin {
   -webkit-from {
     -webkit-transform: rotate(0deg);
     -ms-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   -webkit-to {
     -webkit-transform: rotate(360deg);
     -ms-transform: rotate(360deg);
     transform: rotate(360deg);
   }
 }
 
 @-webkit-keyframes spin {
   from {
     -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   to {
     -webkit-transform: rotate(360deg);
     transform: rotate(360deg);
   }
 }
 
 @keyframes spin {
   from {
     -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   to {
     -webkit-transform: rotate(360deg);
     transform: rotate(360deg);
   }
 }

.friends{
    grid-column: 1/1;
    grid-row: 2/2;
    width: 6vw; height: 6vw;
    border-radius: 50%;
    background: url("./icons/001-add-friend.png") no-repeat;
    background-position: center;
    background-color: #111f38;
    background-size: 4vw 4vw;
}
.friends:hover{
   background-size: 4.6vw 4.6vw;
}
.mygroups{
    grid-column: 1/1;
    grid-row: 3/3;
    width: 6vw; height: 6vw;
    border-radius: 50%;
    background: url("./icons/mygroups.png") no-repeat;
    background-position: center;
    background-color: #111f38;
    background-size: 4.5vw 4.5vw;
}
.mygroups:hover{
   background-size: 5.5vw 5.5vw;
}
.alerts{
    grid-column: 2/2;
    grid-row: 1/1;
    width: 6vw; height: 6vw;
    border-radius: 50%;
    background: url("./icons/notification-min.png") no-repeat;
    background-position: center;
    background-color: #111f38;
    background-size: 4.5vw 4.5vw;
}
.alerts:hover{
   background-size: 5.5vw 5.5vw;
}
.mail{
    grid-column: 2/2;
    grid-row: 2/2;
    width: 6vw; height: 6vw;
    border-radius: 50%;
    background: url("./icons/mail-min.png") no-repeat;
    background-position: center;
    background-color: #111f38;
    background-size: 4.5vw 4.5vw;
}
.mail:hover{
   background-size: 5.5vw 5.5vw;
}
.giftbox{
    grid-column: 2/2;
    grid-row: 3/3;
    width: 6vw; height: 6vw;
    border-radius: 50%;
    background: url("./icons/029-gift-box-min.png") no-repeat;
    background-position: center;
    background-color: #111f38;
    background-size: 4.5vw 4.5vw;
}
.giftbox:hover{
   background-size: 5.5vw 5.5vw;
}
}

.chatheader{
    float:right;
    width:2vw;
    height:2vw;
    margin-right: 7vw;
    margin-top: 2px;
}

.pushtoside{
    background: url("./icons/003-exchange-min.png") no-repeat;
    background-size: 2vw 2vw;
}
.minimize{
    background: url("./icons/down-arrow.png") no-repeat;
    background-size: 2vw 2vw;
}
.minimizeinvert{
   background: url("./icons/down-arrow.png") no-repeat;
   background-size: 2vw 2vw;
   transform: rotate(180deg);
}

