@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
/* CSS HEX 
--jet: #333545ff;
--english-violet: #58445fff;
--magnolia: #f8f0fbff;
--minion-yellow: #ffe347ff;
--jazzberry-jam: #970c4bff;
*/
/* CSS HSL 
--jet: hsla(233, 15%, 24%, 1);
--english-violet: hsla(284, 17%, 32%, 1);
--magnolia: hsla(284, 58%, 96%, 1);
--minion-yellow: hsla(51, 100%, 64%, 1);
--jazzberry-jam: hsla(333, 85%, 32%, 1);
*/

.home-container *{
    font-family: 'Merriweather', serif;
    font-size: 15px;
    color:black;
    box-sizing: border-box;
}
.RORlogo{
    margin: 1vw 1vw;
    width: 6vw;
    height: 7vw;
    object-fit: fill;
}
.menuitemactive{
    background-size: 50px;
}
@media only screen and (min-width: 700px)  {
.home-container{
    display: grid;
    grid-template-columns: 30% 70%;
}
#homecontainermin{
    display: grid;
    grid-template-columns: 7% 93%;
}

.SideNav{
    background-color:white;
    grid-column: 1/2;
    height:100vh;
}
.sidenavfixed{
    position:fixed;
}
.homegrid{
    grid-column: 2/3;
    overflow-x: hidden;
}
#SideNavmin{
    margin-left: 5%;
    padding: 5% 10%;
}
.nav-menu-items li{
    padding: 1vw 3vw;
    width:100%;
    height:7vh;
}
.nav-menu-items a{
    text-decoration: none;
}
.nav-text{
    color:black;
    font-size: 20px;
    align-items: center;
    transform: translate(0%, 17%);
}
.nav-text a{
    text-decoration: none;
}
#homecontainermin .nav-text{
    display:none;
}
.navicon{
    height:30px;
    width: 30px;
    float:left;
    margin-right: 2vh;
}
.friendship{
    background: url("./icons/034-friendship-min.png") no-repeat;
    background-size: 30px;
}
.coin{
    background: url("./icons/002-coin.png") no-repeat;
    background-size: 30px;
}
.trophy{
    background: url("./icons/045-trophy-min.png") no-repeat;
    background-size: 30px;
}
.shortlogo{
    background: url("./icons/shortlogo-min.png") no-repeat;
    background-size: contain;

}
.menuitemactive{
    height:50px;
    width: 50px;
    background-size: contain;
    background-position: center;
    margin-left:-1vw;margin-top:-1vw;
}
@media only screen and (min-width: 700px) and (max-width: 900px)  {
    .menuitemactive{
        height:40px;
        width: 40px;
        background-size: contain;
        background-position: center;
    }
}
.mobile-menu{
    display: none;
}
}

@media only screen and (max-width: 700px)  {
    /*
    .home-container{
        display: grid;
        grid-template-rows: 85% 13%;
        height:100vh;
    }
    */
    .SideNav{display: none;}
    .mobile-menu{

        width:100vw;
        /*height:13vh;*/
        height: 8.3vh;
        max-height: 60px;
        position:fixed;
        bottom: 0;
        left:0;
        right:0;
        outline-color:black;
        outline-style: groove;
        background-color: white;
        font-size: 0;
        z-index: 5;
    }
    .mobile-nav{
        height:100%;
        padding: 1.4vh 2vw;
    }
    .mobile-menu-items{
        text-decoration: none;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap:1vw;
        place-items: center;
    }
    .mobile-menu-items li{
        align-items: center;
    }
    .li-home{
        grid-column: 1/2;
    }
    .li-game-finances{
        grid-column: 2/3;
    }
    .li-education{
        grid-column: 3/4;
    }
    .li-profile{
        grid-column: 4/5;
    }

    .navicon{
        height:30px;
        width: 30px;
        margin-right: 2vh;
    }
    .friendship{
        background: url("./icons/034-friendship-min.png") no-repeat;
        background-size: 30px;
    }
    .coin{
        background: url("./icons/002-coin.png") no-repeat;
        background-size: 30px;
    }
    .trophy{
        background: url("./icons/045-trophy-min.png") no-repeat;
        background-size: 30px;
    }
    .shortlogo{
        background: url("./icons/shortlogo-min.png") no-repeat;
        background-size: contain;
    }
    .menuitemactive{
        height:40px;
        width: 40px;
        background-size: contain;
        background-position: center;
    }
}

/*<-----------------------------------------start of Loading Screen ------------------------------------------->*/
.LoadingScreengrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height:100vh;
    width:100vw;
    box-sizing: border-box;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #c623d5, #d5ba23);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.title{
    position:absolute;
    top:50%; left:50%;
    transform: translate(-50%, -50%);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: calc(12px + 8vh);
    background: -webkit-linear-gradient(#F7DC49, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/*<-----------------------------------------end of Loading Screen ------------------------------------------->*/