.RealmContainer{
    background-image: url('./8c2de92fb8630d2566978f2c17f7daef.jpg');
    background-size: cover;
    height:100%;
    width:93vw;
    background-position: center;
    display: grid;
    grid-template-rows : 3fr 5fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 2vh;
    grid-gap: o.1rem;
    position: fixed;
}

.playerStats *{
    color:white;
    font-family: 'Vollkorn', serif !important;
}
.playerStats{
    grid-row:1/2;
    grid-column: 1/3; 
    display: flex;
    flex-direction: column;
}
.might{
    background-image: url('Rectangle25.png');
    background-repeat: no-repeat;
    background-size: contain;
    width:330px;
    height:55px;
    display: grid;
    grid-template-columns: 3fr 1fr 5fr;
    line-height: 55px;
    padding: 0 2vw;
}
.might img{
    margin:auto;
}
.belowmightcontainer{
    display: flex;
    flex-direction: row;
}
.extrastatscontainer{
    display: flex;
    flex-direction: column;
}
.leader{
}
.leaderRectangle{
    width: 134.39px;
    height: 134.39px;
    border: 5px solid saddlebrown;
    border-radius: 15px;
    position: relative;
}
.leaderLevel{
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: -5px;
    margin-left: -5px;
    padding: 0 5px;
    border: 5px solid saddlebrown;
}
.gems{
    background-image: url('./270237824.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 170px;
    height: 51px;
    display: flex;
    padding: 10px;
}
.gemIcon{
    max-width: 27px;
    max-height: 36px;
}
.gemLevel{
    line-height: 31px;
}
.exp{
    background-image: url('./Rectangle27.png');
    background-size: contain;
    width: 146px;
    height: 49.3px;
    display: flex;
    padding: 7px;
    padding-left: 10px;
}
.expIcon{
    max-width: 27px;
    max-height: 36px;
}
.expLevel{
    line-height: 29.3px;
}
.vipLevel{
    background-image: url('./Rectangle28.png');
    background-size: contain;
    width: 115px;
    height: 38px;
    display: flex;
    padding: 7px;
    padding-left: 10px;
}
.vipLevel1{
    line-height: 24px;
}
.searchBarrealm{
    grid-row: 1/2;
    grid-column: 4/7;
    place-items: start;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.searchBarrealm>input {
    width:100%;
}
#searchQueryInputrealm {
    width: 100%;
    height: 2.8rem;
    background: #f5f5f5;
    outline: none;
    border: none;
    border-radius: 0.625rem;
    padding: 0 3.5rem 0 1.5rem;
    font-size: 1rem;
    }
      
    #searchQuerySubmit {
    width: 3.5rem;
    height: 2.8rem;
    margin-left: -3.5rem;
    background: none;
    border: none;
    outline: none;
    }
    #searchQuerySubmit:hover {
    cursor: pointer;
    }

.shield{
    grid-column: 8/8;
}
.shieldRectangle{
    width:100%;
    height:40px;
    background-color: rgb(5 5 5 / 50%);
    display: flex;
    justify-content: space-between;
}
.shieldRectangle> *{
    padding:4px;
}
.shieldTime{
    color:white;
    line-height: 40px;
    text-align: center;
}
.status{
    grid-column: 10/11;
}
.leftContainer{
    grid-column: 1/2;
    grid-row: 2/3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.leftContainer >*{
margin: 0 auto;
filter: drop-shadow(0.35rem 1rem 0.4rem rgba(0, 0, 0, 0.5));
}
.rightContainer{
    grid-column: 10/10;
    grid-row: 2/3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.resourcesLevel, .iconsGroup{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
}
.map, .timeline{

    filter: drop-shadow(0.35rem 1rem 0.4rem rgba(0, 0, 0, 0.5));
}
.timeline{
    margin:5vh 0;
    width: fit-content;
    padding: 0 5px;
    border: 2px solid red;
    border-radius: 10px;
    background: rgb(241, 241, 77);
    filter: drop-shadow(0.35rem 1rem 0.4rem rgba(0, 0, 0, 0.5));
}
.resourcesLevel * {
    color: white;
}
.foodLevelContainer, .woodLevelContainer, .elixirLevelContainer, .goldLevelContainer, .guildCoinsLevelContainer{
    margin: 5px 0;
    width:100%;
    height:30px;
    background-image: url('./food/resource_level0.png');
    background-size: contain;
    position: relative;
}
.foodLevel,.woodLevel, .elixirLevel, .goldLevel, .guildCoinsLevel{
    position: absolute;
    right:0;
    width:90px;
    height:30px;
    background-image: url('./food/Rectangle41.png');
    background-size: cover;
}
.woodLevel{
    width:120px;
}
.elixirLevel{
    width:145px;
    background-image: url('./food/Rectangle43.png');
}
.goldLevel{
    width:130px;
    background-image: url('./food/Rectangle44.png');
}
.guildCoinsLevel{
    width:70px;
}
.foodicon, .woodicon, .elixiricon, .goldicon, .guildCoinsicon{
    position: absolute;
    right:0;
    width:39px;
    height:39px;
}
.foodLevelNumber, .woodLevelNumber, .elixirLevelNumber, .goldLevelNumber, .guildCoinsLevelNumber{
    position: absolute;
    top: 4px;
    right: 50px;
}
.bottomContainer{
    grid-column: 3/8;
    grid-row:3/3;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.bottomContainer>*{
    max-width:60px;
    max-height:60px;
    filter: drop-shadow(0.35rem 1rem 0.4rem rgba(0, 0, 0, 0.5));
}
/* ----------------------------------------------------------media queries----------------------------------------*/
@media only screen and (max-width: 700px)  {
    .RealmContainer{
        background-image: url('./8c2de92fb8630d2566978f2c17f7daef.jpg');
        background-size: cover;
        background-position: center;
        grid-template-rows: .6fr 1fr .6fr 3fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        height:91.7vh;
        width:100%;
        grid-gap: 0.5rem;
    }
    .playerStats{
        grid-column: 1/7;
        grid-row:1/3;
    }
    .might{
        width:50vw;
        height:8.34vw;
        line-height: 8.34vw;
    }
    .leaderRectangle{
        width: 25vw;
        height: 25vw;
    }
    .gems {
        width: 25vw;
        height:7.48vw;
        line-height: 7.48vw;
        padding:0;
    }
    .exp {
        width: 22vw;
        height:7.421vw;
        line-height: 7.421vw;
        padding:0;
    }
    .vipLevel{
        width: 20vw;
        height:6.571vw;
        line-height: 6.571vw;
        padding:0;
    }
    .vipLevel1 {
        font-size: 70%;
    }
    .searchBarrealm{
        grid-row: 3/4;
        grid-column: 1/11;
        place-items: start;
    }
    .shield{
        grid-column: 8/11;
        grid-row:1/2;
        height:9vw;
    }
    .shieldRectangle {
        width: 28vw;
        height: 9vw;
    }
    .shieldRectangle> *{
        padding:0;
    }
    .shieldTime {
        line-height: 9vw;
        font-size: 60%;
    }
    .status{
        grid-column: 9/11;
        grid-row:2/3;
    }
    .leftContainer{
        grid-column: 1/4;
        grid-row: 4/5;
    }
    .rightContainer {
        grid-column: 7/11;
        grid-row: 4/5;
    }
    .bottomContainer {
        grid-column: 1/11;
        grid-row: 5/6;
    }
    .foodLevelContainer, .woodLevelContainer, .elixirLevelContainer, .goldLevelContainer, .guildCoinsLevelContainer{
        height:2vh;
    }
    .elixirLevel, .foodLevel, .goldLevel, .guildCoinsLevel, .woodLevel {
        width:71px;
        height: 2vh;
    }
    .elixiricon, .foodicon, .goldicon, .guildCoinsicon, .woodicon {
        width: 3vh;
        height: 3vh;
    }
    .elixirLevelNumber, .foodLevelNumber, .goldLevelNumber, .guildCoinsLevelNumber, .woodLevelNumber {
        top: 0;
        right: 10vw;
        font-size: 50%;
    }
    .status img, .guildCoins img, .bank img, .guildChat img, .shop img, .map img, .timeline img{
        width: 15vw;
        height: 15vw; 
    }
    .bottomContainer img{
        width: 10vw;
        height: 10vw;
    }
    .timeline{
        margin:0;
    }
    .woodLevel{
        width:91px;
    }
    .elixirLevel{
        width:78px;
        background-image: url('./food/Rectangle43.png');
    }
    .goldLevel{
        width:44px;
        background-image: url('./food/Rectangle44.png');
    }
    .guildCoinsLevel{
        width:81px;
    }
}

  /*---------------------------------Modal Guildcoins -----------------------------------------*/
  .notactive{display: none; height:0}

  .realmmodalcontainer{
    position:absolute;
    z-index:5;
    margin-top: 22vh;
    border-radius: 8vw 8vw 0 0;
    border: solid 13px white;
    width: 85%;
    margin-left:5%; margin-right: 5%;
    display: grid;
    grid-template-rows: 3% 97%;
    background-size: cover;
  }
  .realmshopfront{
    position:absolute;
    top:0;
    width:82.97vw;
    z-index:1;
  }
  .realmmodalhead{
    grid-row: 1/2;
    position: relative;
  }
  .realmmodalheadcontainer{
    display: flex;
    flex-direction: row;
  }
  .realmmodalheadtext{
    width: 79vw;
  }
  .realmmodalclosebutton{
    z-index:5
  }
  @media screen and (max-width: 700px){
    .realmmodalcontainer{
      width: calc(100vw + 26px);
      margin-left:-13px; margin-right: -13px;
      border-radius: 16vw 16vw 0 0;
    }
    .realmshopfront{
      width:100vw;
    }
    .realmmodalheadtext{
        width: 83vw;
      }
    .realmmodalclosebutton{
        margin-top:-2vh;
    }
  }
#guildcoins{
    background: url('./assets/b1.jpg'), linear-gradient(to right, #262727, #585858);
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 200vh;
    padding-bottom: 36vh;
}
#bank{
    background: url('./assets/goblindone.jpg'), linear-gradient(to right, #262727, #585858);
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 200vh;
    padding-bottom: 36vh;
}
@media screen and (max-width: 700px){
    #bank{
        background-size: 169vw;
        background-position-x: center;
    }
}

#guildcoins *{
    font-family: Roboto;
}
.guildcoinscontainer{
    grid-row:2/3;
    margin-top:30vh;
}
.guildcoinstitle{
    font-size: 300%;
    color:yellow;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
    text-align: center;
    -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
  filter: drop-shadow(0.35rem -0.6rem 0.4rem rgba(0, 0, 0, 0.5));
}
.addguildcoins{
    display: flex;
    margin: 5vw;
    flex-direction: row;
    justify-content: space-between;
}
.addfunds{
    width: 9vw;
    background-color: yellow;
    height: 6vh;
    text-align: center;
    line-height: calc(6vh - 10px);
    border: 5px solid white;
}
.fundstatus{
    width: 9vw;
    background-color: rgba(0, 0, 0, 0.5);
    height: 6vh;
    text-align: center;
    line-height: calc(6vh - 10px);
    border: 5px solid white;
    color:white;
    position: relative;
}
.coin{
    width:2.5vw;
    position: absolute;
    right: 0;
}
.guildcoinstagline{
    font-size: 200%;
    font-family: 'Patrick Hand', cursive !important;
    color:white;
    text-align: center;
    margin: 3vh 0;
}
@media screen and (max-width: 700px){
    .guildcoinscontainer{
        max-width:100vw;
        margin-top:0;
    }
    .guildcoinstitle{
        font-size:200%;
        -webkit-text-stroke-width: 1px;
    }
    .addguildcoins{
        margin:2vw;
    }
    .addfunds, .fundstatus{
        width:33vw;
    }
    .coin{
        width: 9.5vw;
    }
    .guildcoinstagline{
        font-size: 150%; 
    }

}
.guildcoinsservices{
    width:100%;
    padding:5vw;
    display: grid;
    grid-template-areas:
    "h h h h"
    "a a a a"
    "a a a a"
    "a a a a";
    background: rgb(254,0,158);
    background: linear-gradient(180deg, rgba(254,0,158,0.6867121848739496) 0%, rgba(251,252,32,1) 100%);
    place-items: center;
    grid-gap: 3vw;
}
.guildcoinsservicestagline{
    font-size: 200%;
    font-family: Roboto;
    color:white;
    text-align: center;
    width:100%;
    grid-area: h;
}
.serviceicons{
    width:5vw;
    height:5vw;
}
@media screen and (max-width: 700px){
    .guildcoinsservices{
        padding:2vw;
    }
    .guildcoinsservicestagline{
        margin: 2vh 0;
        font-size: 120%;
    }
    .serviceicons{
        width:16vw;
        height:16vw;
    }
}
#school{
    background: url(../../assets/icons/011-school-5.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#nurse{
    background: url(../../assets/icons/001-nurse.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#emergency{
    background: url(../../assets/icons/004-medicine.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#hospital{
    background: url(../../assets/icons/003-hospital-1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#petservice{
    background: url(../../assets/icons/019-animal-kingdom.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#dropservice{
    background: url(../../assets/icons/009-bus.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#teacher{
    background: url(../../assets/icons/012-teacher.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#maid{
    background: url(../../assets/icons/026-mop.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#transport{
    background: url(../../assets/icons/014-pick-up-truck.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#babycare{
    background: url(../../assets/icons/018-pediatrics.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#marketresearch{
    background: url(../../assets/icons/025-economy.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#makeup{
    background: url(../../assets/icons/004-makeup.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#secretary{
    background: url(../../assets/icons/021-secretary.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#salon{
    background: url(../../assets/icons/001-hair-salon.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#dining{
    background: url(../../assets/icons/006-restaurant-cutlery-circular-symbol-of-a-spoon-and-a-fork-in-a-circle.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#extras{
    border-radius: 50%;
    background: rgb(235,32,252);
    background: radial-gradient(circle, rgba(235,32,252,1) 0%, rgba(255,255,255,0.896796218487395) 100%);
    color:white;
    font-weight: bold;
    font-size:120%;
    text-align: center;
    line-height:calc(5vw - 4px);
    border: 2px solid white;
}
@media screen and (max-width: 700px){
    #extras{
        font-size: 90%;
        line-height:calc(16vw - 4px);
    }
}
.background1{
    background: rgb(198,32,252);
    background: linear-gradient(225deg, rgba(198,32,252,1) 0%, rgba(228,204,12,0.896796218487395) 100%);
    padding-bottom: 5vh;
    margin-top: -13vh;
}
.homeguild{
    position: relative;
    margin: 5vw;
    top: 20vh;
    border: 1vw solid white;
    border-radius: calc(3vw - 1px);
    padding: 5vw;
    background: rgb(237,73,192);
    background: linear-gradient(0deg, rgba(237,73,192,0.6) 0%, rgba(242,241,234,0.896796218487395) 100%);
}
.homeguildaddress{
    display: flex;
    margin: 5%;
    flex-direction: row;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.2);
    padding: 1vw 2vw;
    border-radius: calc(1vw - 1px);
}
.guildtown, .addresstown, .welcomeguild{
    display: flex;
    flex-direction: row;
}
.guildcoinsgeneralicon{
    max-width:50px;
    max-height:50px;
}
.guildtowntext{
    line-height:50px;
    margin: 0 1vh;
}
.welcomeguild{
    width: 25vw;
    margin: 0 auto;
}
.welcometoguildtagline{
    font-size: 200%;
    font-family: Roboto;
    color:white;
    text-align: center;
    line-height:90px;
    margin:0 2vw;
}
.bigcastle{
    max-height:90px;
    max-width: 90px;
}
.whatscooking{
    margin: 20% 5% 5% 5%;
    background: url(./assets/96208c77167567.5dfe95aaadf73.gif);
    background-size: cover;
    width: 75vw;
    height: 42.1875vw;
    border-radius: calc(2vw - 1px);
}
.foodordering{
    width:75vw;
    background: rgba(255, 255, 255, 0.2);
    margin: 5%;
    border: 10px solid rgba(0, 0, 0, .4);
    border-radius: calc(1vw - 1px);
    padding:5vw;
    display: grid;
    grid-template-areas:
    "a a a"
    "a a a";
    grid-gap: 3vw;
    place-items: center;
    text-align: center;
}
.food{
    width:10vw;
    height:6vw;
    border-radius: calc(1vh - 1px);
    border: 2px solid rgb(250, 250, 250);
}
@media screen and (max-width: 700px){
    .background1{
        margin-top:0;
    }
    .homeguild{
        top:7vh;
        padding: 3vw;
    }
    .homeguildaddress{
        margin:0;
    }
    .guildcoinsgeneralicon{
        max-width:40px;
        max-height:40px;
    }
    .guildtowntext{
        line-height:40px;
        margin: 0 0.5vh;
        width:27vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .welcomeguild{
        width: 81vw;
        margin: 0 auto;
    }
    .welcometoguildtagline{
        font-size:120%;
        line-height: 20vw;
    }
    .bigcastle{
        max-height: 20vw;
        max-width: 20vw;
    }
    .whatscooking{
        width: 90vw;
        height: 50.625vw;
    }
    .foodordering{
        width:90vw;
        border: 6px solid rgba(0,0,0,.4);
        border-radius: calc(2vw - 1px);
        padding:3vw;
    }
    .food{
        width: 20vw;
        height: 17vw;
    }
}
.background2{
    background: rgb(237,73,192);
    background: linear-gradient(320deg, rgba(237,73,192,1) 0%, rgba(163,38,246,0.896796218487395) 100%);
    padding-bottom: 5vh;
}
.earnfirstguildcoins{
    padding:5%;
}
.earnfirstguildcoinstext{
    color:white;
    font-size:150%;
    font-family: 'Patrick Hand', cursive !important;
    text-align: center;
    padding:0 5%;
}
.earnfirstguildcoinsblock{
    margin: 5vh auto;
    width:50vw;
    background: rgb(47,47,47);
    background: linear-gradient(0deg, rgba(47,47,47,1) 0%, rgba(131,131,131,0.43461134453781514) 100%);
    padding: 5vh;
    border-radius: calc(3vh - 1px);
    border: 2px solid rgb(250, 250, 250);
}
.earnfirstguildcoinsblocktext{
    color:white;
    font-size:150%;
    text-align: center;
}
.earnfirstguildcoinsexplanation{
    display: flex;
    flex-direction: row;
    place-content: center;
    justify-content: space-between;
}
.earnfirstguildcoinsshare{
    margin: 2vh auto;
    width: 10vw;
    height: 4vw;
    text-align: center;
    font-size: 300%;
    font-weight: bold;
    background: rgb(249,250,46);
    background: linear-gradient(180deg, rgba(249,250,46,1) 0%, rgba(240,240,145,1) 28%, rgba(254,254,254,1) 33%, rgba(240,240,145,1) 38%, rgba(249,250,46,1) 100%);
    line-height:4vw;
    border-radius: calc(1vh - 1px);
}
.priviledgedrequests{
    background: rgb(250,224,46);
    background: linear-gradient(180deg, rgba(250,224,46,1) 0%, rgba(124,83,121,1) 100%);
    height:100vh;
    margin: 0 5%;
}
.priviledgedrequestshead{
    height:6vh;
    text-align: center;
    background: rgba(0, 0, 0, .4);
    color: white;
    font-size: 200%;
    line-height: 6vh;
}
@media screen and (max-width: 700px){
    .earnfirstguildcoinsblock{
        width:90vw;
        padding: 2vw 2vh;
    }
    .earnfirstguildcoinsblocktext{
        font-size: 120%;
    }
    .earnfirstguildcoinsexplanation{
        margin:1vh 0;
    }
    .earnfirstguildcoinsexplanation img{
        width:11.5vw;
        height:11.5vw;
    }
    .earnfirstguildcoinsshare{
        width: 30vw;
        height: 13vw;
        font-size: 150%;
        line-height: 13vw;
    }
    .priviledgedrequestshead{
        font-size: 150%;
    }
}
.guildssalute{
    padding: 5vh 0;
    background: url(./assets/a9cfe2145500669.629f850f3e8fe.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
}
.guildsalutetext{
    margin: 0 auto;
    width: 20vw;
    padding: 3vh;
    text-align: center;
    font-size: 200%;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    color: white;
    border: 1vh solid rgb(255,255,255,0.5);
    border-radius: calc(2vh - 1px);
}
.guildsaluteexplanation{
    margin:3vh auto;
    width:30vw;
    display: flex;
    flex-direction: row;
    place-content: center;
    justify-content: space-between;
}
.pinkcastle{
    background: rgb(255,0,176);
    background: radial-gradient(circle, rgba(255,0,176,1) 0%, rgba(255,0,184,0.11528361344537819) 70%, rgba(255,0,185,0) 100%);
}
.handshake,.pinkcastle, .yellowcastle{
    width: 6.3vw;
    height:6.3vw;
}
.handshakecontainer{
    width:12vw;
    height:6.3vw;
    background: rgb(255,239,0);
    background: radial-gradient(circle, rgba(255,239,0,1) 0%, rgba(255,239,0,0.12) 70%, rgba(255,239,0,0) 100%);;
}
.handshake{
    margin-left: calc(6vw - 6.3vw/2);
}
.yellowcastle{
    background: rgb(255,239,0);
    background: radial-gradient(circle, rgba(255,239,0,1) 0%, rgba(255,239,0,0.12) 70%, rgba(255,239,0,0) 100%);
}
@media screen and (max-width: 700px){
    .guildsalutetext{
        width: 57vw;
        padding:0;
        font-size: 150%;
    }
    .guildsaluteexplanation{
        width:88vw;
    }
    .pinkcastle, .yellowcastle{
        width: 23.3vw;
        height: 23.3vw;
    }
    .handshake{
        width: 15vw;
        height: 15vw;
    }
    .handshakecontainer{
        width:24vw;
        height:15vw;
        margin-top: calc(23.3vw/2- 15vw/2);
    }
    .handshake{
        margin-left: calc(12vw - 15vw/2);
    }
}
/*----------------------------start of compete cooperate animation ----------------------------*/
.contentContainer{
    position: relative;
    height: 60px;
    width: 20vw;
    margin: 0 auto;
  }
  .contentContainer *{
    color:white;
    font-size: 120%;
  }

  @media only screen and (max-width: 700px) {
    .contentContainer{
        width: 90vw;
    }
    .contentContainer *{
      font-size:120%;
    }
  }
  .content {
    position: absolute;
    overflow: hidden;
    /*font-size: 35px;
    line-height: 40px;
    */
    color: #ecf0f1;
  }
  .content__container {
    font-weight: 600;
    overflow: hidden;
    height: 60px;
    padding: 10px;
  }
  .content__container:before {
    content: "[";
    left: 0;
  }
  .content__container:after {
    content: "]";
    position: absolute;
    right: 0;
  }
  .content__container:after, .content__container:before {
    position: absolute;
    top: 0;
    color: #16a085;
    font-size: 60px;
    line-height: 60px;
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-name: opacity;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  .content__container__list {
    margin-top: 0;
    padding-left: 1vw;
    text-align: left;
    list-style: none;
    -webkit-animation-name: change;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 10s;
    animation-iteration-count: infinite;
  }
  .content__container__list__item {
    line-height: 50px;
    margin: 0;
  }
  
  @-webkit-keyframes opacity {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @-webkit-keyframes change {
    0%, 12.66%, 100% {
      transform: translate3d(0, 0, 0);
    }
    16.66%, 29.32% {
      transform: translate3d(0, -25%, 0);
    }
    33.32%, 45.98% {
      transform: translate3d(0, -50%, 0);
    }
    49.98%, 62.64% {
      transform: translate3d(0, -75%, 0);
    }
    66.64%, 79.3% {
      transform: translate3d(0, -50%, 0);
    }
    83.3%, 95.96% {
      transform: translate3d(0, -25%, 0);
    }
  }
  @keyframes opacity {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @keyframes change {
    0%, 12.66%, 100% {
      transform: translate3d(0, 0, 0);
    }
    16.66%, 29.32% {
      transform: translate3d(0, -25%, 0);
    }
    33.32%, 45.98% {
      transform: translate3d(0, -50%, 0);
    }
    49.98%, 62.64% {
      transform: translate3d(0, -75%, 0);
    }
    66.64%, 79.3% {
      transform: translate3d(0, -50%, 0);
    }
    83.3%, 95.96% {
      transform: translate3d(0, -25%, 0);
    }
  }

  /*--------------------------end of compete cooperate animation -----------------------------*/
  .guildcoinstabs{
    height:9vh;
    margin:5% 10%;
    display: flex;
    flex-direction: row;
    place-content: center;
  }
.guildcoinstab1, .guildcoinstab2, .guildcoinstab3{
    height:9vh;
    padding:2vw 5vw;
    margin: 0 1vh;
    font-size: 120%;
    font-weight: bold;
}
@media screen and (max-width: 700px){
    .guildcoinstabs{
        margin:5%;
    }
    .guildcoinstab1, .guildcoinstab2, .guildcoinstab3{
        height: 7vh;
        padding: 2vw 2vw;
        margin: 0 0.5vh;
    }
}
.guildcoinstab1{
    background-color: yellow;
}
.guildcoinstab2{
    background-color: rgb(255, 0, 157);
}
.guildcoinstab3{
    background-color: rgb(162, 0, 255);
}
.guildcoinsscrollranks{
    margin: 2vw 10vw 0 10vw;
    height:8vw;
    background: rgb(22,22,22);
    background: linear-gradient(90deg, rgba(22,22,22,0) 0%, rgba(22,22,22,0.6026785714285714) 30%, rgba(22,22,22,0.5970763305322129) 70%, rgba(22,22,22,0) 100%);
    display: flex;
    flex-direction: row;
    place-content: center;
}
.guildrank1, .guildrank2, .guildrank3, .guildrank4, .guildrank5{
    height:6.5vw; width:6.5vw;
    border-radius: 50%;
    border: 2px solid white;
    filter: drop-shadow(0.35rem 1rem 0.4rem rgba(0, 0, 0, 0.5));
    margin: 0.75vw;
}
.guildrank1 img, .guildrank2 img, .guildrank3 img, .guildrank4 img, .guildrank5 img{
    height:4.5vw; width:4.5vw;
    margin: calc(3.25vw - 2.25vw);
}
.violetcastle{
    background: radial-gradient(circle, rgb(153, 0, 255) 0%, rgba(153, 0, 255,0.11528361344537819) 70%, rgba(153, 0, 255,0) 100%);
}
@media screen and (max-width: 700px){
    .guildcoinsscrollranks{
        margin: 2vw 5vw 0 5vw;
        height:18vw;
    }
    .guildrank1, .guildrank2, .guildrank3, .guildrank4, .guildrank5{
        height: 15vw;
        width: 15vw;
        margin: 1vw;
    }
    .guildrank1 img, .guildrank2 img, .guildrank3 img, .guildrank4 img, .guildrank5 img{
        height:11vw; width:11vw;
        margin: calc((7.5vw - 5.5vw) / 2) calc((7.5vw - 5.5vw) / 2 + 0.5vw);
    }
}
/*-------------------------start of guildcoins ruler ------------------------------------*/
.rulercontainer{
    margin: 0 10vw;
    display: flex;
    flex-direction: row;
    width:60vw;
    overflow: clip;
  }
  .rulercontainer *{
    font-size: 100%;
    font-weight: bold;
    font-family: Roboto;
    color:white;
  }
  .marker{
    height:4vh;
    width:2vw;
    border-right: 1px solid #ffffff;
  }
  .boldmarker{
    height:6vh;
    width:2vw;
    border-right: 3px solid #ffffff;
  }
  .boldmarker *{
    margin-top:calc(-4vh + 1.7rem);
    line-height:1rem;
    margin-left: calc( 0.5vw + 50px)
  }
  @media screen and (max-width: 700px){
    .rulercontainer{
        margin: 0 5vw;
        width:90vw;
    }
  }
/*-------------------------end of guildcoins ruler ------------------------------------*/
.guildcoinsotherranks{
    height:100vh;
    background: url(./assets/fc7efd100597425.5f0c884544a65.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y:top;
    background-size: cover;
}
.achievementModalgrid{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 68vh;
}
.leaderboardslot{
    padding: 1vh;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1.5fr;
    height:10vh;
    margin: 0.5vw 0.5vw;
    border-radius: 1vh;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,1) 100%);
    filter: drop-shadow(0.35rem 0.1rem 0.4rem rgba(0, 0, 0, 0.5));
}
.numberedList{
    display: flex;
    flex-direction: row;
}
.number{
    width:5vh; height:5vh;
    border-radius: 50%;
    background-color: yellow;
    text-align: center;
    font-size: 200%;
    margin-top: 4vh;
    transform: translate(0, -50%);
    line-height: 5vh;
}
.leaderprofilepic{
    padding-left:1vh;
}
.leaderprofilepic img{
    width:8vh; height:8vh;
    border-radius: 10%;
  }
  .milestone, .milestone img, .bonus, .bonus img, .trophyicon, .trophyicon img {
    margin: auto 0;
    width:6vh; height:6vh;
  }
  .trophies{
    display: flex;
    flex-direction: row;
  }
  .trophynumber{
    background: white;
    width: 4vw;
    height: 2.5vw;
    border-radius: 50%;
    border: 6px solid yellow;
    text-align: center;
    font-size: 120%;
    transform: translate(0, 40%);
    line-height: 2.5vw;
  }
@media screen and (max-width: 700px){
    .leaderboardslot{
        margin: 0.5vw 1.5vw;
    }
    .leaderboardslot> *{
        max-height:8vh;
    }
    .trophynumber{
      width: 20vw;
      height: 11.5vw;
      line-height: 7vw;
    }
}
.createguild{
    height:100vh;
    background: rgb(255,232,16);
    background: linear-gradient(195deg, rgba(255,232,16,1) 0%, rgba(251,6,246,1) 39%, rgba(254,5,177,1) 64%, rgba(155,10,250,0.9472163865546218) 100%);
}
.guildcoinsmicrobusiness{
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(155,10,250,0.9472163865546218) 100%);
}
.guildcoinsmicrobusinessheader{
    position: relative;
    top:5vh;
    margin:0 10%;
    height:30vh;
    background: rgb(198,198,198);
    background: linear-gradient(20deg, rgba(198,198,198,1) 0%, rgba(218,196,17,0.9472163865546218) 100%);
    border: 1vh solid white;
    border-radius: calc(1vw - 1px);
}
.microbusinessheadline{
    margin:5%;
    color:white;
    font-size:180%;
    text-align: center;
}
.microbusinesssecondline{
    font-size:140%;
    text-align: center;
}
.guildcoinsmicrobusinesslink{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: calc(0.4 * 8vh);
}
.guildcoinsmicrobusinesslinkimg{
    height: 8vh;
    width: 8vh;
    background: url(../../assets/icons/003-link.png);
    transform: rotate(315deg);
    background-repeat: no-repeat;
    background-size: 8vh;
}
.guildcoinsmicrobusinesscontent{
    margin:-1vh 10%;
    height:60vh;
    background: rgb(249,254,26);
    background: linear-gradient(180deg, rgba(249,254,26,1) 0%, rgba(255,255,255,1) 47%, rgba(185,4,245,0.9472163865546218) 100%);
    border-radius: calc(2vh - 1px);
}
.guildcoinsmicrobusinesscontentgrid{
    position: relative;
    top: calc(5% + 1vh);
    display: flex;
    flex-direction: column;
}
.guildcoinsmicrobusinesscontentslots{
    padding: 1vh;
    height: 10vh;
    margin: 0.5vw;
    border-radius: 1vh;
    background: #fff;
    background: linear-gradient(180deg,#fff,hsla(0,0%,100%,.700717787114846) 50%,#fff);
    -webkit-filter: drop-shadow(0.35rem 0.1rem 0.4rem rgba(0,0,0,.5));
    filter: drop-shadow(0.35rem 0.1rem 0.4rem rgba(0,0,0,.5));
    
    display: grid;
    grid-template-areas:
        "a a"
        "a a";
    place-items: center;
    grid-gap: 1vh 10vw;
}
.businessheading{
    font-size: 130%;
    font-weight: bold;
}
.businessaskingprice{
    display: flex;
    flex-direction: row;
}
.businessaskingpriceval{
    line-height: 2vw;
    margin: 0 1vh;
}
.guildcoinsmicrobusinesscontentslots img{
    width:2vw;
}
.businesstokenofappreciation{
    font-size:100%;
    font-family: 'Lobster Two', cursive !important;
}
.businesstokenofappreciationvalue{
    font-size:110%;
    font-family: 'Righteous', cursive !important;
    color:rgb(182, 20, 20);
    font-weight: bold;
}
@media screen and (max-width: 700px){
    .guildcoinsmicrobusinessheader{
        margin:0 5%;
        padding: 5vh 0;
        height:unset;
    }
    .microbusinessheadline{
        font-size:150%;
    }
    .microbusinesssecondline{
        font-size:110%;
    }
    .guildcoinsmicrobusinesscontent{
        margin:-1vh 5%;
    }
    .guildcoinsmicrobusinesscontentslots{
        grid-gap: 1vh 1vw;
    }
    .guildcoinsmicrobusinesscontentslots> *{
        max-height:8vh;
    }
    .businessheading{
        font-size: 90%;
    }
    .businessaskingpriceval{
        line-height: 7vw;
    }
    .guildcoinsmicrobusinesscontentslots img{
        width:7vw;
    }
    .businesstokenofappreciation{
        font-size:80%;
    }
    .businesstokenofappreciationvalue{
        font-size:90%;
    }
}
.superdonationcontainer{
    background: rgb(255,232,16);
    background: linear-gradient(195deg, rgba(255,232,16,1) 0%, rgba(251,6,246,1) 39%, rgba(254,5,177,1) 64%, rgba(155,10,250,0.9472163865546218) 100%);
}
.superdonation{
    position: relative;
    top:5vh;
    margin: 0 10%;
    height:100vh;
}
.superdonationheader{
    height:60vh;
    background: rgb(235,0,255);
    background: url(./assets/radial_comic.png), linear-gradient(0deg, rgba(235,0,255,1) 0%, rgba(123, 8, 161, 0.947) 100%) ;
    background-size: cover;
    background-position-x: center;background-position-y: 30%;
}
.super{
    position: relative;
    width:30vw;
    height:7.42vw;
    margin:0 auto;
    top:5vh;
    background: url(./assets/super.png);
    background-size: cover;
    background-repeat: no-repeat;
}
.donations{
    position: relative;
    width:30vw;
    height:4.26vw;
    margin:0 auto;
    top:5vh;
    background: url(./assets/donations.png);
    background-size: cover;
    background-repeat: no-repeat;
}
.NGOcircles{
    position: relative;
    top:8vh;
    height:12vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: rgba(13, 13, 14, 0.2);
}
.superdonationNGO{
    width:12vh;
    height:12vh;
    background:rgb(214, 214, 214);
    border-radius: 50%;
    text-align:center;
    line-height:12vh;
}
@media screen and (max-width: 700px){
    .superdonation{
        margin: 0 5%;
    }
    .superdonationheader{
        height:40vh;
    }
    .super{
        width:50vw;
        height: 12.42vw;
    }
    .donations{
        width:30vw;
        height:6.3vw;
    }
}
.buildproject{
    height:100vh;
    background: rgb(255,232,16);
    background: url(./assets/clouds.png) 85vw, linear-gradient(195deg, rgba(255,232,16,1) 0%, rgba(251,6,246,1) 39%, rgba(254,5,177,1) 64%, rgba(155,10,250,0.9472163865546218) 100%);
}
.earnguildcoinscontainer{
    height:100vh;
    background: rgb(255,0,164);
    background: linear-gradient(214deg, rgba(255,0,164,1) 0%, rgba(43,1,28,1) 100%);
}
.earnguildcoins{
    position: relative;
    top: 5vh;
    margin:0 10%;
    background:rgba(22, 22, 22, 0.404);
}
.earnguildcoinsheader{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.earnguildcoinslocation{
    margin-top: 3vh;
    height: 40px; width: 12vw;
    background-color: rgb(255 255 255 / 50%);
    display: flex;
    justify-content: space-between;
}
.earnguildcoinsheading{
    font-size: 150%;
    text-align:center;
    font-family: 'Righteous', cursive !important;
    color:white;
    margin-top: 3vh;
}
.earnguildcoinstodo{
    width:12vh;
    height:12vh;
    background: url(./assets/to-do-list.png);
    background-position: center;
    background-size: contain;
}
@media screen and (max-width: 700px){
    .earnguildcoins{
        margin:0 5%;
    }
    .earnguildcoinslocation{
        margin-top: 3vh;
        height: 29px; width: 35vw;
    }
    .earnguildcoinsheading{
        font-size: 90%;
        width:40vw;
    }
    .earnguildcoinstodo{
        width: 15vw;
        height: 15vw;
    }
}
.verifiedbadge{
    height:100vh;
    background: rgb(119,0,255);
background: linear-gradient(180deg, rgba(119,0,255,1) 0%, rgba(247,246,247,1) 100%);
}
.verifiedbadgeheader{
    position: relative;
    top:5vh;
    margin: 0 10%;
    border-radius: calc(3vw - 1px);
    padding:5vh 0;
    background: rgb(210,181,6);
    background: linear-gradient(286deg, rgba(210,181,6,1) 0%, rgba(247,246,247,1) 100%);
    border: 1vh solid white;
}
.verifiedbadgeheadline, .verifiedbadgesecondline{
    font-size:180%;
    color:white;
    text-align: center;
    margin-top:3vh;
}
.verifiedbadgesecondline{
    color:black;
    font-family: 'Righteous', cursive !important;
}
.verifiedbadgecontent{
    height:60vh;
    background: url(./assets/comic.png);
    background-position: center;
    background-size: 85vw;
    background-repeat: no-repeat;
}
@media screen and (max-width: 700px){
    .verifiedbadgeheader{
        margin: 0 5%;
        border-radius: calc(3vw - 1px);
        padding:3vh 0;
    }
    .verifiedbadgeheadline, .verifiedbadgesecondline{
        font-size:130%;
    }
}
 /*---------------------------------end of Modal Guildcoins -----------------------------------------*/


  /*---------------------------------start of Modal Banks -----------------------------------------*/
  #guildcoinstitle{
    margin-top:3vh;
  }
  .bankheading{
    width:80vw;
    max-width: 750px;
    height:20vh;
    margin:0 auto;
    line-height: 20vh;
    text-align: center;
    background: url(./assets/bankheading.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    color:white;
    font-size:200%;
    font-weight: bold;
  }
#bankaddress{
    top:0;
    padding: 3vw;
}
#bankaddress .guildtowntext{
    text-decoration: underline;
}
#bankwelcometoguildtagline{
    line-height: 9vw;
}
#bankbackground1{
    margin-top:0;
    padding-bottom: calc(16vw + 3vw);
}
.upimoneytransfer{
    position: relative;
    top:13vw;
    margin: 0 auto;
    width:75vw; height:35vh;
    padding:3vw;
    background: rgba(0, 0, 0, 0.3);
    border-radius: calc(2vw - 1px);
}
.upimoneytransfertitle{
    width:100%;
    text-align: center;
    font-size:150%;
    color:white;
}
.upimoneytransfercontent{
    margin: 3vh 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}
.scanpaycontainer, .tomobilecontainer, .tobankaccountcontainer{
    display: flex;
    flex-direction: column;
    flex-basis: 0;
}
.scanpay, .tomobile, .tobankaccount{
    width: 7vw;
    height:7vw;
    border-radius: 50%;
    background: #ffffff0e;
}
@media screen and (max-width: 700px){
    .upimoneytransfer{
        width:90vw;
    }
    .scanpay, .tomobile, .tobankaccount{
        width: 20vw;
        height:20vw;
    }
}
.scanpay{
    background: url(../../assets/icons/001-qr-code.png), #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
}
.tomobile{
    background: url(../../assets/icons/002-book.png), #ffffff;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}
.tobankaccount{
    background: url(../../assets/icons/005-money-bag.png), #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
}
.upimoneytransfercontenttags{
    text-align: center;
    color:white;
    margin:1vh 0;
}
.bankfunctions{
    position: relative;
    top:16vw;
    background: rgba(255, 251, 251, 0.4);
    margin: 0 5%;
    padding:5vw;
    display: grid;
    grid-template-areas:
    "a a a a"
    "a a a a"
    "a a a a";
    border-radius: calc(2vh - 1px);
    border:calc(1vw - 1px) solid rgba(46, 45, 45, 0.6);
    
}
.bankfunctionscontentcontainer{

}
.bankfunctionscontent{
    width: 7vw;
    height:7vw;
    border-radius: 50%;
    background: #ffffff0e;
    margin: 0 auto;
}
@media screen and (max-width: 700px){
    .bankfunctions{
        grid-template-areas:
        "a a a"
        "a a a"
        "a a a"
        "a a a";
    }
    .bankfunctionscontent{
        width: 20vw;
        height:20vw;
    }
}
#recharge{
    background: url(../../assets/icons/004-smartphone-2.png), #ffffff;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}
#creditcard{
    background: url(../../assets/icons/005-credit-card.png), #ffffff;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}#terminsurance{
    background: url(../../assets/icons/002-insurance.png), #ffffff;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}#healthinsurance{
    background: url(../../assets/icons/005-healthcare-1.png), #ffffff;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}#investmentplans{
    background: url(../../assets/icons/015-investing.png), #ffffff;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}#carinsurance{
    background: url(../../assets/icons/004-insurance-1.png), #ffffff;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}#bikeinsurance{
    background: url(../../assets/icons/020-motorbike-2.png), #ffffff;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}#familyinsurance{
    background: url(../../assets/icons/021-family-1.png), #ffffff;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}#guaranteedreturn{
    background: url(../../assets/icons/017-return-on-investment.png), #ffffff;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}#childsavings{
    background: url(../../assets/icons/006-daughter.png), #ffffff;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}#retirementplans{
    background: url(../../assets/icons/009-retirement.png), #ffffff;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}
#retirementhome{
    background: url(../../assets/icons/014-retirement-home.png), #ffffff;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}