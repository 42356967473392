a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
.profileheader{
    width:100%;
    height:60vh;
    background: url('./0c19fd111316543.5fff780eaabe2.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin:auto;
}
.profileheader input{
    width:70vw;height:5vh;
    margin: 6vh 11.5vw;
border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: #cdc6c640;
  font-size: 16px;
  color: #fff;
}
@media only screen and (max-width: 700px) {
    .profileheader input{
        margin: 6vh 15vw;
    }
}
.profilesecondheader{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.jobscontainer, .leaderprofilepiccontainer, .matrimonycontainer{
    background-color: #D9D9D9;
}
.jobscontainer{
    border-top-right-radius:100%;
}
.leaderprofilepiccontainer{
    border-bottom: 5px solid #ada7a7be;
}
.matrimonycontainer{
    border-top-left-radius:100%;
}
.jobs, #profile_leaderprofilepic, .matrimony{
    width: 8vh;
    height: 8vh;
    margin: auto;
    border-radius: 5%;
}
.jobicon, .matrimonyicon, #profile_leaderprofilepic img{
    width: 8vh;
    height: 8vh;
    background-color: rgb(56, 56, 56);
    border:5px solid rgb(56, 56, 56);
    border-radius: 50%;
}
.profilegrid{
display:grid;
grid-template-rows: 1fr 1fr ;
}
.profilethirdheader{
    width:100%;
    background-color: #D9D9D9;
}
.profilename, .profileid{
    text-align: center;
}
.profileid{
    font-family: fantasy;
}
.devices{
    margin: 5vh 5vw;
    height: 44vh;
    border-radius: 7vh;
    border: 1.6vw solid #c3c3c3;
    background-color: #ffaa00;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23ffb100' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23ffb800' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23ffbe00' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23ffc500' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23ffcc00' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23ffd914' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23ffe529' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23ffef3d' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23fff852' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23ffff66' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
}
.title {
    color: rgb(50, 50, 50);
    font-weight: bold;
    font-size: 4vh;
    margin: 50px 0;
    text-align: center;
  }
.secondtitle{
    margin-top: 1vh;
    font-size: 60%;
}
  .petalholder {
    position: relative;
    width: 400px;
    height:25vh;
    max-width: 70vw;max-height: 70vh;
    margin: 200px auto;
  }
  
  .petal {
    width: 200px;
    height: 100px;
    max-width: 35vw;
    max-height: 17.5vw;
    background: rgba(233, 30, 99, 0.8);
    box-shadow: 1px 1px 5px 1px #222;
    -moz-border-radius: 100px/50px;
    -webkit-border-radius: 100px/50px;
    border-radius: 100px/50px;
    position: absolute;
    top: 0px;
    transform-origin: right center;
  }
  
  .petal1 {
    transform: rotate(0deg);
  }
  
  .petal2 {
    transform: rotate(45deg);
  }
  
  .petal3 {
    transform: rotate(90deg);
  }
  
  .petal4 {
    transform: rotate(135deg);
  }
  
  .petal5 {
    transform: rotate(180deg);
  }
  
  .petal6 {
    transform: rotate(225deg);
  }
  
  .petal7 {
    transform: rotate(270deg);
  }
  
  .petal8 {
    transform: rotate(315deg);
  }
  .manageguilds{
    margin: 5vh 5vw;
    border-radius: 7vh;
    border: 1.6vw solid #c3c3c3;
    height:60vh;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' %3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2380F'/%3E%3Cstop offset='1' stop-color='%23f40'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpattern id='b' width='24' height='24' patternUnits='userSpaceOnUse'%3E%3Ccircle fill='%23ffffff' cx='12' cy='12' r='12'/%3E%3C/pattern%3E%3Crect width='100%25' height='100%25' fill='url(%23a)'/%3E%3Crect width='100%25' height='100%25' fill='url(%23b)' fill-opacity='0.1'/%3E%3C/svg%3E");
    background-attachment: fixed;
  }