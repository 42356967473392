.sticktobottom{
  position: fixed;
  bottom:0;
  width: 93vw;
}
@media only screen and (max-width: 700px)  {
  .sticktobottom{
    width: 100vw;
  }
}
.sticktobottom .puppy{
  display: none;
}
.puppychatcontainer{
  position: relative;
}

.chatcontainer{
  position:absolute;
  width: 100%;
  color: white;
  background-color: #282c34;
}
.chatcontainer header {
  width: 100%;
  min-width: 30px;
  box-sizing: border-box;
  background-color: #181717;
  padding-right: 5vw;
}
.chatgridinactive{
  display: none;
}
 /*
@media only screen and (max-width: 700px)  {
  .chatcontainer, .sticktobottom{
    margin-bottom: 13vh;
  }
}
*/
@media only screen and (max-width: 700px)  {
  .addmargintochat{
    margin-bottom: 11vh;
  }
}
@media only screen and (max-width: 900px)  {

.chatcontainer, .sticktobottom{
  height:47vh;
}
.downArrow{
  grid-column: 2/3;
  position: absolute;
  width:45px; height:45px;
  top: 3px;
  background: url("./icons/down-arrow.png") no-repeat;
  background-size: 45px 45px;
  background-size: contain;

}
.downArrowmaximize{
  transform: rotate(180deg);
}
.chatcontainer header {
  height: 3vh;
}
.chatcontainerinactive{
  height:3vh;
}
.sticktobottominactive{
  height: calc(3vw + 60px);
}
.sticktobottominactive .puppy{
  display: block;
}
  .chatgrid{
    display: grid;

  }
  .chatgrid-inactive{
    display: none;
  }

  .chatbone{

    max-width: 900px;
    height: 37vh;
    background-color: rgb(40, 37, 53);
  }
  .leftchat, .chatright{display: none;}
  .chatbonegrid{
    display: grid;
    grid-template-rows: 28vh 9vh;
  }
  .chatbonegrid main {
    height: 28vh;
  }
  .inputbox{
    grid-row: 2/span 1;
    height: 9vh;
  }
  form.chatform>input {
    height:9vh;
  }
}
@media only screen and (min-width: 700px) and (max-width: 900px)  {
  .chatbone {
    height:44vh;
  }
  .chatbonegrid{
    grid-template-rows: 35vh 9vh;
  }
  .chatbonegrid main{
    height:35vh;
  }
}
@media screen and (min-width: 900px) {

  .chatcontainer, .sticktobottom{
    height:30vw;
  }
  .chatcontainer{
    width: 93vw;
  }
  .puppychatcontainer{
    display: none;
  }
  .chatcontainer header {
    height: 3vw;
  }
  .chatcontainerinactive{
    height:3vw;
  }
  .sticktobottominactive{
    height:3vw;
  }
.chatgrid{
  display: grid;
  grid-template-columns: 1.25fr 2fr 1.25fr;
}
.chatgridinactive{
  display: none;
}
.leftchat{
  grid-column: 1/2;
}
.chatright{
  grid-column: 3/4;
} 
.chatbone{
  grid-column: 2/3;
  height: 27vw;
  background-color: rgb(40, 37, 53);
}
.chatbonegrid{
  display: grid;
  grid-template-rows: 24vw 3vw;
}
.chatbonegrid main {
  height: 24vw;
}
.inputbox{
  grid-row: 2/span 1;
  height: 3vw;
}
form.chatform>input {
  height:3vw;
}

}
.chatbonegrid main {
    grid-row: 1/span 1;
    box-sizing: border-box;
    padding: 1vw;
    overflow-y: scroll;
    overflow-x: hidden;
  }  
  .chatbonegrid>main::-webkit-scrollbar {
    width: 0.7rem;
  }
  
  .chatbonegrid>main::-webkit-scrollbar-track {
    background: #1e1e24;
  }
  
  .chatbonegrid>main::-webkit-scrollbar-thumb {
    background: #6649b8;
  }
form.chatform {
  display: grid;
  grid-template-columns: 70% 30%;
}
  
form.chatform>button {
  grid-column: 2/span 1;
  background-color: rgb(56, 56, 143);
}
form.chatform>input {
  grid-column: 1/span 1;
  background-color: rgb(24, 23, 23);
  line-height: 1.5;
  width: 100%;
  margin-bottom: 1px;
  font-size: 1.5rem;
  background: rgb(58, 58, 58);
  color: white;
  outline: none;
  border: none;
  padding: 0 10px;
  }
  
  form.chatform>button {
    background-color: #282c34; /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-size: 1.25rem;
  }
  
  form.chatform>button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .chatbonegrid ul,.chatbonegrid li {
    text-align: left;
    list-style: none;
  }
  
  .chatbonegrid p {
    max-width: 600px;
    margin-bottom: 12px;
    line-height: 24px;
    padding: 10px 20px;
    border-radius: 25px;
    position: relative;
    color: white;
    text-align: center;
  }
  
  .chatbonegrid .message {
    display: flex;
    align-items: center;
  }
  
  
  .chatbonegrid .sent {
    flex-direction: row-reverse;
  }
  
  .chatbonegrid .sent p {
    color: white;
    background: #0b93f6;
    align-self: flex-end;
  }
  .chatbonegrid .received p {
    background: #e5e5ea;
    color: black;
  }
  
  .chatbonegrid .profilepic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 2px 5px;
  }
  .sendbutton{
    width:4vw;
  }

  /*------------Puppy animation begins-------------------*/
  .puppy .head_eyebrow__right, .puppy .head_eyebrow__left {
    background: #563930;
    width: 16px;
    height: 3px;
    position: absolute;
    z-index: 5;
    bottom: 60px;
  }
  
  .puppy .head_eye__right .pupil, .puppy .head_eye__left .pupil {
    width: 8px;
    height: 12px;
    background: black;
    border-radius: 100%;
  }
  .puppy .head_eye__right .pupil:after, .puppy .head_eye__left .pupil:after {
    content: "";
    background: white;
    width: 4px;
    height: 4px;
    left: 2px;
    top: 1px;
    border-radius: 10px;
    display: block;
    position: absolute;
  }
  
  .paw_left, .paw_right {
    position: absolute;
    z-index: 5;
    width: 30px;
    height: 20px;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    background: #b8a392;
  }
  
  .puppy .head_eye__left, .puppy .head_eye__right {
    background: white;
    position: absolute;
    z-index: 4;
    width: 16px;
    height: 20px;
    border-radius: 100%;
  }
  
  .puppy .tail, .puppy, .paw, .puppyhide {
    position: absolute;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .puppy .head_earfold__left, .puppy .head_earfold__right {
    position: absolute;
    background: #563930;
    width: 24px;
    height: 38px;
    border-radius: 100%;
    z-index: 10;
    bottom: 60px;
  }
  
  .puppy .head_ear__left, .puppy .head_ear__right {
    position: absolute;
    background: #563930;
    width: 20px;
    height: 28px;
    border-radius: 100%;
    z-index: 10;
    bottom: 60px;
  }
  
  .puppy .head_eye__right .iris, .puppy .head_eye__left .iris {
    position: relative;
    background: #a3ebe8;
    width: 13px;
    height: 16px;
    border-radius: 100%;
  }
  
  .puppy .head_mouth__right, .puppy .head_mouth__left, .puppy .head_mouth__top {
    background: #f8f5f4;
    position: absolute;
    z-index: 3;
  }
  
  .puppy .head_bottom__left, .puppy .head_bottom__right {
    background: #cdb7a5;
    position: absolute;
    z-index: 3;
    width: 33px;
    height: 40px;
    border-radius: 100%;
  }
  
  .paw_left__part, .paw_right__part {
    width: 10px;
    height: 10px;
    background: #b8a392;
    border-radius: 10px;
    position: absolute;
    bottom: -4px;
  }
  .paw_left__part:nth-of-type(1), .paw_right__part:nth-of-type(1) {
    left: 0px;
  }
  .paw_left__part:nth-of-type(2), .paw_right__part:nth-of-type(2) {
    left: 10px;
  }
  .paw_left__part:nth-of-type(3), .paw_right__part:nth-of-type(3) {
    left: 20px;
  }
  
  .puppyhide {
    height: 230px;
    z-index: 12;
    bottom: -464px;
  }
  
  .paw {
    width: 130px;
    z-index: 10;
  }
  .paw_left {
    left: 0;
    top: -10px;
  }
  .paw_right {
    right: 0px;
    top: -10px;
  }
  
  .puppy {
    width: 130px;
    height: 0px;
  }
  .puppy .intro {
    top: -179px;
    position: absolute;
    text-align: center;
    font-size: 20px;
    color: #a25a4b;
  }
  .puppy .wrap {
    position: relative;
    -webkit-animation: body 10s infinite;
            animation: body 10s infinite;
  }
  .puppy .body {
    position: absolute;
    z-index: 1;
    width: 100px;
    height: 100px;
    left: 13px;
    top: -46px;
    border-radius: 100%;
    background: #cdb7a5;
  }
  .puppy .tail {
    transform-origin: 5px 100px;
    width: 10px;
    height: 60px;
    top: -40px;
    background: #563930;
    border-radius: 50px;
    -webkit-animation: tail 1s infinite;
            animation: tail 1s infinite;
  }
  .puppy .head {
    position: absolute;
    top: 35px;
    left: 5px;
    -webkit-animation: head 1s infinite;
            animation: head 1s infinite;
  }
  .puppy .head_eyebrow__left {
    bottom: 124px;
    left: 30px;
    transform: rotateZ(-14deg);
    -webkit-animation: eyebrow 10s infinite;
            animation: eyebrow 10s infinite;
  }
  .puppy .head_eyebrow__right {
    bottom: 124px;
    left: 74px;
    transform: rotateZ(14deg);
    -webkit-animation: eyebrow2 10s infinite;
            animation: eyebrow2 10s infinite;
  }
  .puppy .head_earfold {
    position: relative;
    top: -6px;
  }
  .puppy .head_earfold__right {
    bottom: 120px;
    left: 79px;
    transform: rotateZ(-30deg);
  }
  .puppy .head_earfold__left {
    bottom: 120px;
    left: 18px;
    transform: rotateZ(30deg);
  }
  .puppy .head_ear {
    position: relative;
    top: -6px;
  }
  .puppy .head_ear__left {
    bottom: 130px;
    transform: rotateZ(-50deg);
    left: 30px;
  }
  .puppy .head_ear__right {
    bottom: 130px;
    transform: rotateZ(50deg);
    left: 70px;
  }
  .puppy .head_top {
    background: #cdb7a5;
    width: 70px;
    height: 70px;
    position: absolute;
    z-index: 3;
    left: 25px;
    bottom: 80px;
    border-radius: 80px;
  }
  .puppy .head_bottom__right {
    left: 67px;
    bottom: 70px;
  }
  .puppy .head_bottom__left {
    left: 20px;
    bottom: 70px;
  }
  .puppy .head_eye__left {
    left: 32px;
    bottom: 100px;
  }
  .puppy .head_eye__left .iris {
    top: 2px;
    left: 2px;
  }
  .puppy .head_eye__left .pupil {
    position: relative;
    left: 4px;
    bottom: 12px;
    -webkit-animation: eye 10s infinite;
            animation: eye 10s infinite;
  }
  .puppy .head_eye__right {
    left: 72px;
    bottom: 100px;
  }
  .puppy .head_eye__right .iris {
    top: 2px;
    left: 2px;
  }
  .puppy .head_eye__right .pupil {
    position: relative;
    left: 4px;
    bottom: 12px;
    -webkit-animation: eye 10s infinite;
            animation: eye 10s infinite;
  }
  .puppy .head_tongue {
    position: absolute;
    background: pink;
    width: 14px;
    height: 30px;
    border-radius: 40px;
    z-index: 3;
    bottom: 50px;
    left: 54px;
    -webkit-animation: tongue 1s infinite;
            animation: tongue 1s infinite;
  }
  .puppy .head_nose {
    background: #563930;
    width: 16px;
    height: 10px;
    border-radius: 20px;
    position: absolute;
    z-index: 5;
    left: 52px;
    bottom: 83px;
  }
  .puppy .head_nose:after {
    content: "";
    display: block;
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    border-radius: 10px;
    width: 4px;
    left: 3px;
    bottom: 4px;
    height: 4px;
  }
  .puppy .head_mouth__top {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    left: 45px;
    bottom: 73px;
  }
  .puppy .head_mouth__left {
    width: 33px;
    height: 25px;
    border-radius: 100%;
    left: 33px;
    bottom: 65px;
  }
  .puppy .head_mouth__left:after {
    width: 30px;
    height: 20px;
    background: #f8f5f4;
    content: "";
    position: absolute;
    z-index: 5;
    border-radius: 5px;
    left: 3px;
    bottom: 11px;
    display: block;
    transform: rotatez(-43deg);
  }
  .puppy .head_mouth__right {
    width: 33px;
    height: 25px;
    border-radius: 100%;
    left: 56px;
    bottom: 65px;
  }
  .puppy .head_mouth__right:after {
    width: 30px;
    height: 20px;
    background: #f8f5f4;
    content: "";
    position: absolute;
    z-index: 5;
    border-radius: 5px;
    left: -1px;
    bottom: 11px;
    display: block;
    transform: rotatez(42deg);
  }
  
  @-webkit-keyframes tail {
    0% {
      transform: translateY(-50%) rotateZ(-45deg);
    }
    50% {
      transform: translateY(-50%) rotateZ(45deg);
    }
    100% {
      transform: translateY(-50%) rotateZ(-45deg);
    }
  }
  
  @keyframes tail {
    0% {
      transform: translateY(-50%) rotateZ(-45deg);
    }
    50% {
      transform: translateY(-50%) rotateZ(45deg);
    }
    100% {
      transform: translateY(-50%) rotateZ(-45deg);
    }
  }
  @-webkit-keyframes head {
    0% {
      top: 35px;
    }
    50% {
      top: 40px;
    }
    100% {
      top: 35px;
    }
  }
  @keyframes head {
    0% {
      top: 35px;
    }
    50% {
      top: 40px;
    }
    100% {
      top: 35px;
    }
  }
  @-webkit-keyframes tongue {
    0% {
      bottom: 50px;
    }
    50% {
      bottom: 46px;
    }
    100% {
      bottom: 50px;
    }
  }
  @keyframes tongue {
    0% {
      bottom: 50px;
    }
    50% {
      bottom: 46px;
    }
    100% {
      bottom: 50px;
    }
  }
  @-webkit-keyframes eyebrow {
    0% {
      transform: rotateZ(-3deg) translateY(4px);
    }
    30% {
      transform: rotateZ(-3deg) translateY(4px);
    }
    35% {
      transform: rotateZ(-17deg) translateY(-2px);
    }
    100% {
      transform: rotateZ(-17deg) translateY(-2px);
    }
  }
  @keyframes eyebrow {
    0% {
      transform: rotateZ(-3deg) translateY(4px);
    }
    30% {
      transform: rotateZ(-3deg) translateY(4px);
    }
    35% {
      transform: rotateZ(-17deg) translateY(-2px);
    }
    100% {
      transform: rotateZ(-17deg) translateY(-2px);
    }
  }
  @-webkit-keyframes eyebrow2 {
    0% {
      transform: rotateZ(3deg) translateY(4px);
    }
    30% {
      transform: rotateZ(3deg) translateY(4px);
    }
    35% {
      transform: rotateZ(17deg) translateY(-2px);
    }
    100% {
      transform: rotateZ(17deg) translateY(-2px);
    }
  }
  @keyframes eyebrow2 {
    0% {
      transform: rotateZ(3deg) translateY(4px);
    }
    30% {
      transform: rotateZ(3deg) translateY(4px);
    }
    35% {
      transform: rotateZ(17deg) translateY(-2px);
    }
    100% {
      transform: rotateZ(17deg) translateY(-2px);
    }
  }
  @-webkit-keyframes body {
    0% {
      top: 170px;
    }
    10% {
      top: 60px;
    }
    40% {
      top: 60px;
    }
    41% {
      top: 60px;
    }
    51% {
      top: 0px;
    }
    90% {
      top: 0px;
    }
    100% {
      top: 170px;
    }
  }
  @keyframes body {
    0% {
      top: 170px;
    }
    10% {
      top: 60px;
    }
    40% {
      top: 60px;
    }
    41% {
      top: 60px;
    }
    51% {
      top: 0px;
    }
    90% {
      top: 0px;
    }
    100% {
      top: 170px;
    }
  }
  @-webkit-keyframes eye {
    0% {
      left: 4px;
      bottom: 12px;
    }
    10% {
      left: 4px;
      bottom: 12px;
    }
    15% {
      left: 0px;
      bottom: 12px;
    }
    20% {
      left: 8px;
      bottom: 12px;
    }
    25% {
      left: 4px;
      bottom: 12px;
    }
  }
  @keyframes eye {
    0% {
      left: 4px;
      bottom: 12px;
    }
    10% {
      left: 4px;
      bottom: 12px;
    }
    15% {
      left: 0px;
      bottom: 12px;
    }
    20% {
      left: 8px;
      bottom: 12px;
    }
    25% {
      left: 4px;
      bottom: 12px;
    }
  }
  /*---------------------------------------Puppy animation ends-----------*/