@import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;1,500&family=Raleway:wght@500,900&display=swap');
nav.menu{
  grid-row:1/2;
  grid-column: 1/4;
  padding:3rem 5rem;
  height:8vh;
  display:grid;
  grid-template-columns: 2fr 5fr 1fr 1fr 1fr;
  grid-gap:1.5rem;
  /*background-image: linear-gradient( 45DEG, rgba(255,0,0,0), #405DE6, #5851DB, #833AB4,  #C13584, #E1306C, #FD1D1D);*/
}
nav.menu *{
  font-family: 'Vollkorn', serif;
  font-size: 1.5rem;
  color:white;
}
.logo, .logo img{
  grid-column: 1/2;

}
.navitemcompany{
  grid-column: 3/4;
  margin:auto;
}
.navitempricing{
  grid-column: 4/5;
  margin:auto;
}
.navitemlogin{
  grid-column: 5/6;
  margin:auto;
  padding:1vw 2vw;
  background-color: #FD0172;
  border: 2px solid white;
  border-radius: 0.5vh;
  color:white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.headerbutton{
  box-sizing: border-box;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  color: #fff;
  border: 3px solid #db0d5c;
  background-image: -webkit-linear-gradient(30deg, #db0d5c 50%, transparent 50%);
  background-image: linear-gradient(30deg, #db0d5c 50%, transparent 50%);
  background-size: 700px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.headerbutton:hover{
  background-position: 100%;
  color: #db0d5c;
}

/*popup css*/
.btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 10px;
  transition: background .5s;
}
/*
.btn:hover {
  background: #00898E;
}*/
.popup {
  position: absolute;
  padding: 10vw;
  max-width: 500px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: rgba(255,255,255,.9);
  visibility: hidden;
  opacity: 0;
  /* "delay" the visibility transition */
  -webkit-transition: opacity .5s, visibility 0s linear .5s;
  transition: opacity .5s, visibility 0s linear .5s;
  z-index: 1;
}
.popup:target {
  visibility: visible;
  opacity: 1;
  /* cancel visibility transition delay */
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.popup-close {
  position: absolute;
  padding: 10px;
  max-width: 500px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: rgba(255,255,255,.9);
}
.popup .close {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
  color: #000;
  transition: color .3s;
  font-size: 2em;
  line-height: .6em;
  font-weight: bold;
}
.popup .close:hover {
  color: #00E5EE;
}

.closepopup {
  background: rgba(0,0,0,.7);
  cursor: default;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  /* "delay" the visibility transition */
  -webkit-transition: opacity .5s, visibility 0s linear .5s;
  transition: opacity .5s, visibility 0s linear .5s;
}
.popup:target + .close-popup{  
  opacity: 1;
  visibility: visible;
  /* cancel visibility transition delay */
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}/*End of popup css*/


.appheader{
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.9)), url(../199729203.jpg);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 10fr;
  grid-gap:3rem;
}
.megaWin{
  grid-row:2/4;
  grid-column: 1/2;
}
.megawincontainer{
  position: relative;
  width: 100%;
  height: 100%;
  /*display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  */

}
.megawinimage{
  position: absolute;
  margin-left: -11vw;
  width: 50vw;
  height: auto;
}
.star{
  position:absolute;
  width: 9rem;
  height: 9rem;
  background-image: url("../assets/star.png");
  background-size: contain;
  background-position: center;
}
#star1{
  top: 3vw;
  left: -3.5vw;
}
#star2{
  top: 2vw;
  left: 4vw;
}
#star3{
  top: 2.5vw;
  left: 13vw;
}
#star4{
  top: 5.5vw;
  left: 22vw;
}
#star5{
  top: 12.5vw;
  left: 26vw;
}
#star6{
  top: 21.5vw;
  left: 23vw;
}
#star7{
  top: 24.5vw;
  left: 14vw;
}
#star8{
  top: 24.5vw;
  left: 5vw;
}

.headertext{
  grid-row:2/4;
  grid-column: 2/3;
}
.headertext *{
  margin-top:11vh;
  color:white;
  font-family: 'Raleway', sans-serif;
  font-weight:800;
}
@media screen and (min-width: 800px) {
.headertext>#h1{
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
}
.getStarted{
  margin: 0;
  width: 21vw;
  height: 7vw;
  background-image: url("../assets/Button.png");
  background-position: center;
  background-size: cover;
}
.headskeleton{
  grid-row:2/4;
  grid-column: 3/3;
  background-size: contain;
  background-position-x: right;
  background-image: url("../assets/IMG_176311.png"), url("../assets/IMG_176211.png");
  background-repeat: no-repeat;
}
@media screen and (max-width: 800px) {
  .appheader{
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.9)), url(../assets/IMG_17631.png), url(../assets/IMG_17621.png), url(../199729203.jpg);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 2fr 3fr;
    grid-gap:1rem;
  }
  nav.menu{
    grid-row:1/2;
    grid-column: 1/4;
    padding:1rem 2rem;
    height:8vh;
    display:grid;
    grid-template-columns: 2fr 5fr 1fr;
    grid-gap:3rem;
    /*background-image: linear-gradient( 45DEG, rgba(255,0,0,0), #405DE6, #5851DB, #833AB4,  #C13584, #E1306C, #FD1D1D);*/
  }

  .navitemcompany{
    display:none;
  }
  .navitempricing{
    grid-column: 3/4; 
  }
  .headskeleton{
    display:none;
  }
  .headertext{
    grid-row:2/3;
    grid-column: 1/4;
    padding:5vh;
    text-align: center;
  }
  .megaWin{
    grid-row:3/4;
    grid-column: 1/4;
    padding:5vh;
  }
  .getStarted{
    margin:0 auto;
    width: 42vw;
    height: 14vw;
  }
  .megawinimage{
    margin-left: 0;
    width: 46vh;
    height: auto;
  }
  #star1{
    top: 3vw;
    left: -3.5vw;
  }
  #star2{
    top: -4vw;
    left: 16vw;
  }
  #star3{
    top: -4.5vw;
    left: 34vw;
  }
  #star4{
    top: 2.5vw;
    left: 52vw;
  }
  #star5{
    top: 21.5vw;
    left: 59vw;
  }
  #star6{
    top: 33.5vw;
    left: 40vw;
  }
  #star7{
    top: 34.5vw;
    left: 23vw;
  }
  #star8{
    top: 32.5vw;
    left: 5vw;
  }
}
#h1{
  font-size:calc(12px + 2.4vw);
}
#h2{
  font-size:calc(12px + 1.5vw);
}
#h3{
  font-size:calc(12px + 1vw);
}

.bookcontainer{
  display:grid;
  grid-template-columns: 30% 55%;
}
.firstbook{
  position: relative;
grid-column: 1/1;
}
.firstbook:hover .overlay {
  opacity: 1;
}
.secondbook{
  position: relative;
  grid-column: 2/2;
}
.secondbook:hover .overlay {
  opacity: 1;
}
.books{
  width:100%;
  filter: drop-shadow(0px 0px 10px #4535c8);
  animation:glow 4s linear infinite;
}

#second-book{
  max-width:65%;
}
.overlay {
  position: absolute; 
  bottom: 0; 
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1; 
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}
/* glowing animation */
@keyframes glow{
  0% {
    filter: drop-shadow(0 0 1rem rgb(9, 255, 0.5));
  }
  33% {
    filter: drop-shadow(0 0 1rem rgba(1, 6, 255, 0.5));
  }
  66% {
    filter: drop-shadow(0 0 1rem rgba(255, 0, 0, 0.5));
  }
  100% {
    filter: drop-shadow(0 0 1rem rgb(9, 255, 0.5));
  }
}


.inputboxcontainer{
  display:grid;
  grid-template-columns: 70% 30%;
}
.materialtextfield{
  grid-column: 1/1;
  }
.materialtextfield input{
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 1.4rem;
  padding: 0.5rem 0.5rem;
}
.materialtextfield input:invalid{
  box-shadow: none;
}
.materialtextfield  input:focus{
  outline: none;
}
.materialtextfield  label{
  padding-left: 2vw;
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-size: 0;
  pointer-events: none;
  position: relative;
  text-transform: uppercase;
  width: 100%;
}
.materialtextfield label::before{
  content: attr(data-content);
  position: relative;
  transition: all 0.2s ease;
  will-change: font-size, top;
}
.materialtextfield  label::after{
  bottom: 0.9rem;
  content: '';
  height: 0.3rem;
  left: 50%;
  position: absolute;
  transition: all 0.2s ease;
  width: 0;
  will-change: width, left;
}
.materialtextfield label::before, .materialtextfield input[required]:focus ~ .materialtextfield label::before{
  font-size: 0.75rem;
  top: -4.25rem;
}

.materialtextfield input:focus ~ .materialtextfield label::after{
  top: 7rem;
  left: 0%;
  width: 100%;
}

.materialtextfield input:invalid ~ .materialtextfield label::before{
  font-size: 1rem;
}
#getstartedbutton{
  box-sizing: border-box;
  grid-column: 2/2;
  max-width:18vw;
  max-height:6vw;

  padding-bottom: 0px;
}
.divider {
  height:7px;
  /*background:repeating-linear-gradient(-45deg,#fff 0px,#fff 4px,#000 4px,#000 8px);*/
  background-color:rgba(0, 0, 0, 0.7);
}
/*.homepage-container{
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 10%;
}*/
.homepage{
  background-color: #f5f5f5;
}
.testimony{
  margin: 5vw;
  font-family: 'Vollkorn', serif;
  font-weight: 500;
  padding: 3vw;
  border: 2px solid rgb(5 5 5 / 15%);
  border-radius: 2vw;
  text-align: center;
}
.areaContainer{
  margin:10vw;
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-gap:2vw;
  height:60vh;
  
}
.areaPartition1{
  grid-column: 1/2;
}
.areaPartition1 *{
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
  padding:5vh 10vh;
}
.areaPartition2{
  grid-column:2/2;
  margin: 0 auto;
}
@media screen and (max-width: 800px) {
  .areaContainer{
    margin:10vw;
    display:grid;
    grid-template-rows: 2fr 9fr;
    grid-template-columns: none;
    grid-gap:2vw;
    height: -webkit-fill-available;
    
  }
  .areaPartition1{
    grid-row: 1/2;
    grid-column: unset;
  }
  .areaPartition1 *{
    text-align: center;
  }
  .areaPartition2{
    grid-row:2/3;
    grid-column: unset;
    margin: 0 auto;
  }
}
.mobileFrame{
  position:relative;
  height:74vh;
  width:36vh;
  /*
  height: 74vh;
  width: 73vh;
  background-image: url(/static/media/314632797.4cf850b4.jpg);
  background-position: bottom;
  background-position-x: center;
  background-position-y: bottom;
  background-size: contain;
  background-size: 49vh 57vh;
  background-repeat: no-repeat;
  */
}
#classes{
  
}
.mobileFrameImage{
  position: absolute;
  z-index: 15;
}
@media screen and (max-width: 800px) {
.mobileFrameImage{
  height: 77vh;
}
}
.mobilebackground{
  position:absolute;
  top: 8px;
  left: 5px;
  width:36vh;
  height:74vh;
}
.newtonmobilebackground{
  position: absolute;
  top: 8px;
  left: 8px;
  width: 35vh;
  height: 74vh;
}
.businessmobilebackground{
  position: absolute;
  top: 8px;
  left: 8px;
  width: 35vh;
  height: 74vh;
}
#classesBackground1{
  background-image: url(../assets/homepage/314632797small.jpg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index:4;
}
#classesBackground2{
  background-image: url(../assets/homepage/319969448_162.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index:11;
}
.display_none{
  display:none;
}
#classesBackground3{
  background-image: url(../assets/homepage/314632797-1.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index:5;
}
#classesBackground4{
  background-image: url(../assets/homepage/314632797-2.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index:6;
}
#classesBackground5{
  background-image: url(../assets/homepage/314632797-3.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index:7;
}
#classesBackground6{
  background-image: url(../assets/homepage/314632797-4.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index:8;
}
#classesBackground7{
  background-image: url(../assets/homepage/314632797-5.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index:9;
}
#classesBackground8{
  background-image: url(../assets/homepage/314632797-6.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index:10;
}
#newtonBackground1{
  background-image: url(../assets/homepage/b20f1f135806855.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index:10;
}
#businessBackground1{
  background-image: url(../assets/homepage/hp2-jordan-peterson.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index:10;
}
/*

.segmenttextheader{
  font-size:calc(12px + 2.4vw);
}
.segmenttextcontent{
  font-size:calc(12px + 1.5vw);
}
#coaching,#careermap{
  grid-template-columns: 45% 45%;
  column-gap: 10%;
}
*/
/* <----------------------------start of Scrolling Text ------------------------------------->*/
.scrollingText{
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
#scrollingTextgetStarted{
  position: relative;
  top:45%;
  margin:0 auto;
}
.scrollingText .scroll{
  width: 100%;
  display: flex;
}

.scrollingText .scroll div{
  color: #000;
  font-size: 4em;
  background: #fff;
  white-space: nowrap;
  font-weight: 900;
  text-transform: uppercase;
  animation: animate 40s linear infinite;
  animation-delay: -40s;
}

.scrollingText .scroll div:nth-child(2){
  animation: animate2 40s linear infinite;
  animation-delay: -20s;
}

.scrollingText .scroll div span{
  -webkit-text-stroke: 2px #000;
  color: transparent;
}

@keyframes animate{
  0%{
    transform: translateX(100%);
  }
  100%{
    transform: translateX(-100%);
  }
}

@keyframes animate2{
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(-200%);
  }
}

.scrollingText .scroll.text1{
  transform: rotate(30deg) translateY(200px) translateX(-200px);
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  width: 100%;
}

/* <----------------------------end of Scrolling Text ------------------------------------->*/
.section{
  display:grid;
}
#careermap{
  height:28.43vw;
  grid-template-rows: 1fr 3fr 1fr;
  background-image: url(../assets/homepage/ea95d7118962031.gif);
  background-size: cover;
  background-position: center;
  min-height: 200px;
}
.careermapheading{
  grid-row: 1/2;
}
.careermapheadingcontent{
  margin: 0 auto;
  width: 29vh;
  height: 21vh;
  background-image: url(../assets/homepage/banner.png);
  background-size: cover;
  background-position: center;
  line-height: 21vh;
  text-align: center;
  font-family: 'Vollkorn';
  font-weight: 800;
  font-size: x-large;
}
.careermapbottom{
  grid-row: 3/3;
}
#earnmoney{
  margin:6vw;
  grid-template-columns: 1fr 1fr;
  grid-gap:2vw;
  place-items: center;
}
.taskscontainer{
  grid-column: 1/2;
  height: 50vh;
  background-image: linear-gradient(to bottom, #dde580, #ecba5c, #f87d93, #f580c8, #c9a8f3);
  overflow-x: hidden;
  overflow-y: scroll;
}
.taskArea{
margin: 0 auto;
width: 90%;
height: 10vh;
background: url(../assets/homepage/tasks/Rectangle16.png);
background-position: center;
background-size: cover;
box-sizing: border-box;
}
.taskinternalcontainer{
  width: 52vh;
  height: 7vh;
  padding:1vh;
  display: grid;
  grid-gap:1vh;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  place-items: center;
}
.taskname{
  grid-column: 1/2;
  grid-row: 1/2;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: 400;
}
.reward{
  grid-column: 2/2;
  grid-row: 1/2;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
}
.exppoints{
  grid-column: 1/2;
  grid-row: 2/2;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
}
.taskcategory{
  grid-column: 2/2;
  grid-row: 2/2;
  font-family:cursive;
}
.coinstar{
  margin: 0 1vh;
  width:3vh;
  height:3vh;
}
.sectiontext{
  grid-column: 2/2;
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
  padding: 5vh 10vh;
  font-size: calc(12px + 1.5vw);
}
@media screen and (max-width: 800px) {
  #earnmoney{
    margin:6vw;
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr;
    grid-gap:2vw;
    place-items: center;
  }
  .taskscontainer{
    grid-row: 1/2;
    grid-column: unset;
  }
  .sectiontext{
    grid-row: 2/3;
    grid-column: unset;
  }
}
#plans{
  padding-left:0vw;
}
#pricing{
  font-weight: 100;
  grid-column: 1/span 2;
}
.textcenter{
  text-align: center;
}
.bold{
  font-weight: 400;
}
.careermap{
  max-width:25vw;
  height:19vw;
}

.segment{
  background-image:linear-gradient(rgba(0, 0, 0, 0.904), rgb(0, 0, 0)) ;
  display: grid;
  color:white;
  padding:10vh;
}
h4{
  text-align: center;
  font-size:calc(12px + 1vw);
}
@media screen and (max-width: 800px) {
  .segment{
    padding:0;
    padding:5vh;
  }
}
/* Plans */
.tablecontainer{
  margin:2vh;
}
.container1 {
  margin: 0 auto;
  width: 90%;
  max-width: 1200px;
  grid-column: 1/span 2;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

.grid-1-5 {
  border: 2px solid #5d4e65;
  padding: 1.25em;
  position: relative;
  text-align: center;
  transition: all .2s ease-in-out;
}
.no-border{
  border:none;
  pointer-events: none;
  text-align: right;
}




.grid-1-5:hover {
  background-color: #53455b;
  *zoom: 1;
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FF53455B', endColorstr='#FF201D22');
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzUzNDU1YiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzIwMWQyMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #53455b), color-stop(100%, #201d22));
  background-image: -moz-linear-gradient(top, #53455b 0%, #201d22 100%);
  background-image: -webkit-linear-gradient(top, #53455b 0%, #201d22 100%);
  background-image: linear-gradient(to bottom, #53455b 0%, #201d22 100%);
  border-top: 2px solid #ec7a37;
  border-bottom: 2px solid #ff4f69;
  box-shadow: 0px 0px 10px 0px #323232;
  transform: scale(1.025);
  z-index: 2;
}
.grid-1-5:hover:before, .grid-1-5:hover:after {
  content: "";
  position: absolute;
  background-color: #f67d35;
  *zoom: 1;
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFF67D35', endColorstr='#FFFF4F68');
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y2N2QzNSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmNGY2OCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #f67d35), color-stop(100%, #ff4f68));
  background-image: -moz-linear-gradient(top, #f67d35 0%, #ff4f68 100%);
  background-image: -webkit-linear-gradient(top, #f67d35 0%, #ff4f68 100%);
  background-image: linear-gradient(to bottom, #f67d35 0%, #ff4f68 100%);
  top: -2px;
  bottom: -2px;
  width: 2px;
}
.grid-1-5:hover:before {
  left: -2px;
}
.grid-1-5:hover:after {
  right: -2px;
}
h2, h3, p, ul {
  margin: 0;
}

h2 {
  font-size: 1em;
  font-weight: 400;
  margin: 0 0 0.5em;
}

h3 {
  font-size: 1.5em;
  letter-spacing: 0.0625em;
  margin: 0 0 0.3333333333333333em;
}

p {
  font-size: 0.875em;
}

p, ul {
  margin: 0 0 1.5em;
}

ul {
  color: #796583;
  font-size: 0.75em;
  list-style-type: none;
  padding: 0;
}
ul li {
  margin: 0 0 0.8333333333333333em;
}

.uppercase, .button, h2 {
  text-transform: uppercase;
}

sup, .small {
  font-size: 0.6125em;
}
.underline{
  text-decoration: underline;
}

/*--new plan table--*/
.fontblack{
  color:#0a0a0a
}
.tablecontainersmallscreen{
  display: none;
}
.table-container, .table-container-smallscreen{
  padding-right:10vw;
  grid-column: 1/span 2;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
  color:black;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

/*
@media screen and (max-width: 600px) {
  .segment {
    max-height: none;
  }
  .table-container{
    display: none;
  }
  .table-container-smallscreen {
    display: block;
}
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    
    //* aria-label has no advantage, it won't be read inside a table
    //content: attr(aria-label);
    
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
  tr:first-child{
    display:block;
  }
}
*/
/*--JOBS--*/
.container {
  grid-column: 1/span 2;
	align-items: center;
	justify-content: center;
  font-size:calc(12px + 4.5vw);
  font-family: 'Lato', sans-serif;
  text-transform:uppercase;

 }
.container h1 {
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all .2s;
}
.container p {
  font-size:calc(12px + 2vw);
   text-transform:lowercase;
  font-weight:bold;
  background-image: linear-gradient(132deg, #F4D03F 0%, #16A085 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color:transparent;
}
.container h1:hover {
  background-image: linear-gradient(43deg, #FFCC70  0%, #C850C0 46%, #4158D0 100%);
  transform:scale(1.2);
}


/*--Footer--*/
#footer{
  padding-top: 5vw;
  padding-bottom: 5vw;
  grid-template-columns: 25% 25% 25% ;
  column-gap: 5vw;
  height: 40vh;
}
#footer  a:link, #footer  a:visited{
  text-decoration: none;
  color:white;
}
.footerhead{
  grid-column: 1/span 4;
}
#footerhead{
  text-transform: uppercase;
}
.col1, .col2, .col3{
  display: grid;
  grid-template-rows: 33% 33% 33%;
}
.row1{
  grid-row: 1/1;
}
.row2{
  grid-row: 2/2;
}
.row3{
  grid-row: 3/3;
}

/* -----------------------------------------------------------Media Queries smaller screens -- ------------------------------*/
@media screen and (min-width: 1120px) {
  .grid-1-5 {
    width: 29%;
  }
  .grid-1-5:nth-child(odd), .grid-1-5:nth-child(3n+1) {
    clear: none;
  }
}
@media screen and (max-width: 1140px) {
  label::before, input[required]:focus ~ label::before{
    top: -3.5rem;
}
}
@media screen and (max-width: 950px) {
  label::before, input[required]:focus ~ label::before{
    top: -3.3rem;
}

}
@media screen and (max-width: 900px) {
  .headerstoryboard {
    margin-left: 15vw;
    margin-right: 15vw;
  }
  label::before, input[required]:focus ~ label::before{
    top: -3rem;
}
  #footer{
  height:auto;
  min-height: 60vh;
  }
  .segment > div{
    grid-column: 1/span 2;
  }
  .no-border{
    border: 2px solid #5d4e65;
    pointer-events: none;
}
  #plans{
    padding-left:10vw;
  }
  .segment .footerhead {
    grid-column: 1/span 4;
  }
}
@media screen and (min-width: 800px) {
  .grid-1-5 {
    width: 33.3333333%;
  }
  .grid-1-5:nth-child(3n+1) {
    clear: left;
  }
  .grid-1-5:nth-child(odd) {
    clear: none;
  }

}
@media screen and (min-width: 700px) {
  .grid-1-5 {
    float: left;
    width: 50%;
  }
  .grid-1-5:nth-child(odd) {
    clear: left;
  }
  #plans{
    max-height: none;
    height:90vh;
  }
}
@media screen and (max-width: 800px) {
  label::before, input[required]:focus ~ label::before {
    top: -2.9rem;
}
.sidebar{
  display: none;
}
.main{
  grid-column: 1/span 2;
}
}
@media screen and (max-width: 700px){
  .navicons{
    margin-left:-7vw;
  }
  #loginbutton{
    width:25vw;
  }
  .logo, .logo img {
    width: 30vw;
    height: 6vw;
  }
  .headerstoryboard {
    margin-left: 10vw;
    margin-right: 10vw;
  }
  .material-textfield {
    grid-column: 1/span 2;
  }
  input {
    height: 10vw;
    font-size: 1.4rem;
  }
  label::before, input[required]:focus ~ label::before {
    top: -4rem;
}
  #getstartedbutton {
    max-width: none;
    max-height: none;
    width: 40vw;
    padding-bottom: 20px;
}
  #plans{
    max-height: none;
    height:90vh;
  }
}
@media screen and (max-width: 666px){
  label::before, input[required]:focus ~ label::before {
    top: -3.7rem;
}
}
@media screen and (max-width: 666px){
  label::before, input[required]:focus ~ label::before {
    top: -3.4rem;
}
}
@media screen and (max-width: 540px){
  label::before, input[required]:focus ~ label::before {
    top: -3rem;
}
input:invalid ~ label::before {
  font-size: 0.8rem;
}
}
/*---------------------------------------Privacy Policy----------------------------------------------------------------*/
.privacypolicy{
  font-family: 'Lato', sans-serif;
}
.privacyheader{
  background-image: url(../35307971595.jpg);
  min-height:100vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.privacyheadertext{
  padding: 10vw;
}
.fontwhite{
  color:white;
}
.privacypolicy h1{
  font-size:calc(12px + 6vw);
  font-family: 'Lato', sans-serif;
}
.privacypolicy h2{
  font-family: 'Roboto', sans-serif;
  font-size: calc(12px + 2.5vw);
  padding:0 13vw 0;
  text-transform: none;
}
.privacypolicy h3{
  font-family: 'Roboto', sans-serif;
  font-size: calc(12px + 2vw);
}
.privacypolicy h4{
  font-size: calc(12px + 1.5vw);
}
.privacypolicy p{
  font-size: calc(12px +5.5vw);
}
.privacypolicy > a:link, .privacypolicy > a:visited{
  text-decoration: none;
  color: rgb(0, 0, 0);
  
}
.privacypolicy > a:active {
  color: rgb(116, 34, 184);
}
.subhead{
  padding: 3vw;
  font-weight: 700;
}
.backshade{
  background-color: rgb(230, 230, 230);
}
.padding7{
  padding:7vw;
}
.padding10{
  padding:10vw;
}
.subhead h4{
  padding: 0 20vw 0;
}
.privacycontenttextmargin{
  padding: 7vw;
}
.privacycontenttext{
  padding: 5vw 10vw 0;
  display: grid;
  grid-template-columns: 20% 80%;
}
.privacyheader1, .privacyheader2{
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: calc(12px + 4vw);
  color: rgb(255, 255, 255);
  background: #8441d0;
  padding:10vw;
  min-height: 12vw;
}
#privacyheader2{
  background: #cf3092;
}
#number{
  position:absolute;
  font-size: calc(12px + 20vw);
  margin: -10vw -20vw 0 0;
  opacity:50%;
}
.privacycontenttextsticky{
  display: grid;
  grid-template-columns: 30% 70%;
}
.sticky{
  align-self: start;
  position: sticky;
  top: 10vw;
}
.privacymain{
  padding:0 5vw 3vw 5vw;
}
.privacypolicy .privacymain h2 {
  padding: 0;
}
.privacypolicy ul{
  color:black;
  font-size: 1em;
  list-style-type: square;
}

/* FAQ HelpCenter Account Jobs CompanyInformation ContactUs */
.logoheader{
  background-image: url(../redback.jpg);
}
.logoheader nav {
   height:auto;
}
.padding{
  padding: 10vw 20vw 10vw 20vw;
}
.jobs .jobdetail{
  font-size: calc(12px + 1vw);
  text-transform: none;
    font-weight: normal;
    background-image: none;
    background-color: white;
    color: black;
    -webkit-text-fill-color: black;
}
.underline{
  text-decoration: underline;
}

/*Modal css*/
.modaloverlay{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(0,0,0,0.7);
  z-index:999;
}
.modal{
  position:fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFF;
  padding: 50px;
  z-index:1000;
}
.threeGrid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap:3vw;
}
.threeGrid>*, .columnGrid>.one{
  background-color: #FFF;
  border-radius: 0.5vw;
  min-height:50vh;
  border: 1px solid #cbcbcb;
}
.threeGrid>.one{

}
.oneItem{
  padding: 1.5vw 5vw 1.5vw 5vw;
  border-bottom: 1px solid #cbcbcb;
}
.oneItem h4{
  padding: 0;
}
.columnGrid>.one{
  width:50%;
  min-height:unset;
  margin: 3vh auto 0 auto;
  padding: 2vw;
  text-align: center;
  font-size: 150%;
  filter: drop-shadow(0.3rem 0.5rem 0.3rem rgba(0, 0, 0, 0.5));
}

